import {
    Box,
    Paper,
    Button,
    TableContainer,
} from "@mui/material";
import { useState, useEffect, useRef, useCallback } from 'react';
import { DataGridPro, GridActionsCellItem, GridRowModes, useGridApiRef, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import '../DispatchStyle.css'
import CustomColumnMenu from "./CustomColumnMenu";
//import TenderModal from "./TenderModal";

export default function CommonTable(props) {
    const { rowData, tableColumns, isLoading, tabletype } = props
    /**
     * updated on 06-oct-2023
     * handle column resizing
     */
    const apiRef = useGridApiRef();
    /**
     * ENd
     */
    useEffect(() => {
        apiRef.current.autosizeColumns({
            includeHeaders: false,
            includeOutliers: true
        })
    }, [rowData])

    const QuickSearchToolbar = () => {
        return (
            <Box
                sx={{
                    p: 0.5,
                    pb: 0,
                    display: "flex",
                    justifyContent: 'flex-end',

                }}
            >
                <GridToolbarQuickFilter
                    sx={{
                        marginTop: "-75px",
                        '& input': {
                            color: 'antiquewhite'
                        },
                        '& .MuiSvgIcon-root': {
                            color: 'white', // Text color
                        },
                        '& .MuiButtonBase-root:after': {
                            color: 'antiquewhite'
                        }
                    }}
                />
            </Box>
        );
    }

    return (
        <DataGridPro
            rows={rowData}
            columns={tableColumns}
            editMode="row"
            columnHeaderHeight={30}
            rowHeight={30}
            checkboxSelection={false}
            //disableRowSelectionOnClick {...row}
            hideFooter={true}
            hideFooterRowCount={true}
            loading={isLoading}
            slots={{
                columnMenu: CustomColumnMenu,
                toolbar: QuickSearchToolbar
            }}
            // disableDensitySelector
            // disableColumnFilter
            // disableColumnSelector
            // slotProps={{
            //     toolbar: {
            //         showQuickFilter: true,
            //     },
            // }}

            getCellClassName={(params) => {
                if (params.row?.isPastStop === 'yes' && params?.field === "apptDateTime") {
                    return 'highLightWithRed';
                }
                if (params.row?.spec === "HAZMAT" && params?.field === "spec") {
                    return 'highLightWithRed';
                }
                if (params.row?.isLfdCutOld === 'yes' && params?.field === "lfdcut" && params.row?.stop?.split('/')?.[1] <= 1) {
                    return 'highLightWithRed';
                }
            }}
            apiRef={apiRef}
            sortingOrder={['desc', 'asc']}
        />
    )
}

