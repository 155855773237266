import React, { useContext } from "react";
import { Grid, Button } from "@mui/material";
import { useEffect, useState } from "react";
import StopsForm from "./StopsForm";

import {
    deleteOrderStopData,reorderOrderStopData
} from "../../../store/actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import orderContext from '../orderContext';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { getEdiOrderDriverList } from '../../../store/actions/ediOrderAction';


const GridContainer = (props) => (
    <Grid container   {...props.gridProps}>
        {props.children}
    </Grid>
)

const GridItem = (props) => (
    <Grid item {...props.gridProps} xs={12} md={12} lg={12} >
        {props.children}
    </Grid>
)


export default function StopsList(props) {


    const dispatch = useDispatch();
    const params = useParams();

    const { stopsRef, orderType, orderTypeOptions, disableFull, toTemplate, controlNumber, locationNumber,nonMatchingFields,isEdiScreen } = props


    const [selectedOrderType, setSelectedOrderType] = useState(null)
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackContent, setSnackContent] = useState('')
    const [countNewStop, setCountNewStop] = useState(0);
     // START: On location change reload URL with new location
  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );
    // Selected ORder Type
    useEffect(() => {
        if (orderType) {
            const selected = orderTypeOptions.filter(item => item.value == orderType)
            setSelectedOrderType(selected[0].value)
            console.log(selected, orderType)
        }
    }, [orderType])

    /**
     *  29-07-204
     *  Temporary Handling all Drivers for location
     */
    const [allDrivers, setAllDrivers] = useState([]);
    useEffect(() => {
        if (ordersLocation) {
            handleDriverNumberLookup()
        }
    }, [ordersLocation])

    const [stopsList, setStopsList] = useState(stopsRef.current)
    const { setIsStateChanged } = useContext(orderContext);
    const handleFieldChanges = () => {
        /**
         * 28-09-2023
         * update global context that field has changed
         */
        console.log('stop list fields are changed')
        setIsStateChanged(true);
        /** END */
    }

    const handleAddStop = (currentStop, position) => {
        console.log("currentStop",currentStop)
        let stops = stopsRef.current.sort((a, b) => a.newStop - b.newStop)
        // let newStopSeq = stops.length > 0 ? stops[stops.length - 1].newStop + 1 : 1
        let newStopSeq = -1
        let currentIndex = stops.findIndex(item => item.newStop === currentStop.newStop)
        let beforeStops = [...stops]
        let afterStops = [...stops]
        if (position === 'before') {
            beforeStops = beforeStops.slice(0, currentIndex)
            afterStops = afterStops.slice(currentIndex)
            // newStopSeq = currentStop.newStop  
            newStopSeq = countNewStop - 1
        }
        else if (position === 'after') {
            beforeStops = beforeStops.slice(0, currentIndex + 1)
            afterStops = afterStops.slice(currentIndex + 1)
            // newStopSeq = currentStop.newStop + 1     
            newStopSeq = countNewStop - 1
        }
        afterStops =controlNumber?afterStops: afterStops.map((item, index) => ({ ...item, stop: item.stop + 1 }))
        console.log('Before', beforeStops)
        console.log('After', afterStops)
        console.log('Next Stop', newStopSeq)
        stops = [
            ...beforeStops,
            {
                stop: newStopSeq,
                consignee: "",
                code: "",
                pickupNumber: "",
                name: "",
                address: "",
                city: "",
                state: "",
                zip: "",
                Phone: "",
                contact: "",
                contactEmail: "",
                poPU: "",
                shipperBillOfLadding: "",
                appointmentDate: "",
                appointmentTime: "",
                totalPieces: "",
                totalWeight: "",
                commodity: "",
                doorSeal: "",
                arriveDate: "",
                arriveTime: "",
                spotDate: "",
                spotTime: "",
                unloadDate: "",
                unloadTime: "",
                departDate: "",
                departTime: "",
                availDate: "",
                availTime: "",
                etaDate: "",
                etaTime: "",
                arriveTruck: '',
                // arriveDriver: '',
                arriveTruckStatus: '',
                // arriveDriverStatus: '',
                departTruck: '',
                // departDriver: '',
                departTruckStatus: '',
                // departDriverStatus: ''
                tempPulp: "",
                internalNotes: "",
                driverNotes: ""
            },
            ...afterStops
        ]
stops=stops.map((item,index) => {
    if (item.stop) {
        if(controlNumber){ 
        item.newStop = index + 1}else{
            item.stop = index + 1
            item.newStop = index + 1 
        }
     
    }
    return item;
});
        console.log('Add Stop list', stops)
        setCountNewStop(newStopSeq)
        setStopsList([...stops])
        stopsRef.current = [...stops]
        // handleFieldChanges()
    }
    const handleDeleteStop = (deleteStop) => {
        let stopReqParams={}
        if (deleteStop.isServerRecord !== undefined && deleteStop.stop !== null) {
            let confirmAlert = window.confirm('Are you sure?');
            if (confirmAlert) {
                setSnackOpen(true)
                setSnackContent({ severity: "info", message: "deleting stop..." })
                stopReqParams = {
                    location: params.locationNumber, control: params.controlNumber, lineNo: deleteStop.stop
                }
                dispatch(deleteOrderStopData(stopReqParams)).then((res) => {
                    // Update local state. 
                    if (res.status === 'success') {
                       
                        let stops = stopsRef.current.sort((a, b) => a.newStop - b.newStop)
                        let updatedStopsList = stops?.filter(item => item.newStop !== deleteStop.newStop).map((item, index) => {
                            if (item.stop) {
                                // item.newStop = index + 1
                                if(params.controlNumber){ 
                                    item.newStop = index + 1}else{
                                      
                                        item.stop = index + 1
                                        item.newStop = index + 1 
                                       
                                    }
                            }
                            return item;
                        });
                        const updatedStopListOrder= {
                            lines: updatedStopsList.map(item => ({
                                line: item.stop,
                                newLine: item.newStop
                            }))
                        };
                        
  dispatch(reorderOrderStopData(stopReqParams,updatedStopListOrder)).then((res) => {
    if (res.status === 'success') {
    setStopsList([...updatedStopsList])
    stopsRef.current = [...updatedStopsList]
    setSnackOpen(true)
    setSnackContent({ severity: "info", message: "stop deleted" })
    }

  })


                    }
                })
            }
        } else {
            let stops = stopsRef.current.sort((a, b) => a.newStop - b.newStop)
            let updatedStopsList = stops.filter(item => item.newStop !== deleteStop.newStop).map((item, index) => {

                if (item.stop) {
                    item.newStop = index + 1;
                    if(params.controlNumber){ 
                        item.newStop = index + 1}else{
                            item.stop = index + 1
                            item.newStop = index + 1 
                        }
                 
                }
                return item;
            });
            console.log("updatedStopsList", updatedStopsList)
            stopsRef.current = [...updatedStopsList]
            setStopsList([...updatedStopsList])
        }
        // let updatedStopsList = stopsList.filter(item => item.stop !== deleteStop.stop)
        // updatedStopsList = updatedStopsList.sort((a, b) => a.stop - b.stop)
        // updatedStopsList = updatedStopsList.map((item, index) => ({ ...item, stop: index + 1 }))
        // stopsRef.current = [...updatedStopsList]
        // setStopsList([...updatedStopsList])
    }

    useEffect(() => {
        console.log('StopsList Changes', stopsList)
        if (stopsList.length === 0) {
            handleAddStop(null)
        }
    }, [stopsList])


    const canEnterTruckDetailsArrive = (stopId, stopsListRef) => {
        if (stopId === 0) {
            return true; // First stop, so can enter details
        }
        const previousStopId = stopId - 1;
        const previousStopData = stopsListRef.find(stop => stop.stop === previousStopId);
        if (previousStopData) {
            return previousStopData.departTruck !== '' && previousStopData.departTruckStatus !== ''
        } else {
            return true
        }
    }

    const canEnterTruckDetailsDepart = (currentStopData) => {
        return currentStopData.arriveTruck !== '' && currentStopData.arriveTruckStatus !== '';
    };
    const handleClose = (event) => {
        setSnackOpen(false);
    };

    // Temporary Handling on Driver List
    const handleDriverNumberLookup = async () => {
        dispatch(
          getEdiOrderDriverList(
            params.locationNumber
          )
        ).then((res) => {
          const data=res.map((item) => {
            return {id:item?.safetyId, 
            driverName:`${item?.firstName ? item.firstName : ""} ${item?.middleInitial ? item.middleInitial : ""} ${item?.lastName ? item.lastName : ""}` || ""}
        })
          setAllDrivers(data);
        })
      };
console.log("nonMatchingFields",nonMatchingFields)
    return (
        <div style={{ display: 'flex', flexDirection: "column", width: '100%', justifyContent: 'center' }}>
            {/* <Button onClick={handleAddStop} fullWidth={false}> Add Stop </Button> */}
            <GridContainer gridProps={{ justifyContent: 'left' }}>
                {stopsList.map((item, index) => {
                    return (
                        <GridItem key={index} >
                            <StopsForm
                                stop={item}
                                stopsRef={stopsRef}
                                handleDeleteStop={handleDeleteStop}
                                handleAddStop={handleAddStop}
                                isTemplateScreen={toTemplate}
                                selectedOrderType={selectedOrderType}
                                disableFull={disableFull}
                                locationNumber={locationNumber}
                                controlNumber={controlNumber}
                                canEnterTruckDetailsArrive={canEnterTruckDetailsArrive}
                                canEnterTruckDetailsDepart={canEnterTruckDetailsDepart}
                                setStopsList={setStopsList}
                                allDrivers={allDrivers}
                                nonMatchingFields={nonMatchingFields}
                                isEdiScreen={isEdiScreen}

                            />
                        </GridItem>
                    )
                })}
            </GridContainer>
            < Snackbar open={snackOpen} style={{ marginTop: "5%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={handleClose} >
                <Alert onClose={handleClose} severity={snackContent.severity} sx={{ width: '100%' }}>
                    {snackContent.message}
                </Alert>
            </Snackbar>
        </div>
    );
}