import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { Paper, TableContainer, Box} from "@mui/material";
import { DataGridPro} from '@mui/x-data-grid-pro';

export default function ShipperConsigneeTable(props) {
                        useState('')
    const { rows,rowsData, setSelectedRow , isLoading,handleQueryPageChange,rowsCount,handleSortChangeData,setAddNewShipperCons,shipperTableApiCall, setEditShipperClicked ,editShipperClicked ,addNewShipperCons } = props;

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 1,
    });
    const [sortData, setSortData] = useState({
        field:"name", 
        sort:"asc"
        
    });
    const dispatch = useDispatch();

    const tableColumns = [
        
        { field: 'code', headerName: 'Code', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1,minWidth:100 },
        { field: 'yard', headerName: 'Yard', headerClassName: 'MuiDataGrid-columnHeaders', flex: 0.5, minWidth: 50},
        { field: 'name', headerName: 'Name', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1,minWidth:100 },
        { field: 'address', headerName: 'Address', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1 ,minWidth:100},
        { field: 'city', headerName: 'City', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1,minWidth:100},
        { field: 'state', headerName: 'State', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1 ,minWidth:100},
        
    ]

    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );

    const handleRowClick = (params) => {
        console.log("params",params)
        console.log("rowsData",rowsData)
        if(editShipperClicked || addNewShipperCons){
            let confirmAlert = window.confirm("Please save your changes or click 'OK' to open selected shipper consignee");
            if (confirmAlert) {
                const filterRow=rowsData.filter((item,index) => index === params?.row?.id)

                setSelectedRow(filterRow[0])
                setAddNewShipperCons(false)
                setEditShipperClicked(false)
             
          }
        }else{
            const filterRow=rowsData.filter((item,index) => index === params?.row?.id)
            setSelectedRow(filterRow[0])
            setAddNewShipperCons(false)
            setEditShipperClicked(false)
         
        }
       
    };
  
    const handlePageChange = (params) => {
        console.log("sortData",sortData)
        setPaginationModel({ pageSize: params.pageSize, page: params.page })
        handleQueryPageChange(params,sortData)
        // shipperTableApiCall(ordersLocation.locationNumber,{page: params.page, pageSize: params.pageSize  })
    }
    const handleSortChange = (sortModel) => {
        console.log("sortmodel",sortModel)
        const sortDataToServer={
            field:"name", 
            sort:"asc"
            
        }
        if(sortModel[0]){
            console.log("if true")
            const { field, sort } = sortModel[0];
            sortDataToServer.field=field==="code"?"shipperCode":field
            sortDataToServer.sort=sort
        }

       console.log("sortDataToServer",sortDataToServer)
        setSortData(sortDataToServer)
        handleSortChangeData(paginationModel,sortDataToServer)
       
        
    };
    return (

        <TableContainer component={Paper} style={{ width: '100%', height: '100%'}}>
            <Box sx={{
                width: '100%',
                overflowX: 'auto',
                height: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                '& .tableTH': {
                    fontWeight: 700
                },
                '& .MuiDataGrid-columnHeaders': {
                    // backgroundColor: "rgba(9, 29, 163, 1)",
                    backgroundColor: "rgb(11, 67, 135)",
                    color: "rgba(255, 255, 255, 1)",
                    fontSize: '12px'
                },
                '& .MuiDataGrid-sortIcon': {
                    opacity: 1,
                    color: "rgba(255, 255, 255, 1)",
                },
                '& .MuiDataGrid-menuIconButton': {
                    opacity: 1,
                    color: "rgba(255, 255, 255, 1)",
                },
                '& .super-app-theme--cell': {
                    backgroundColor: 'white',
                    fontSize: '11px'

                },


            }}>
                <DataGridPro
                    rows={rows}
                    rowCount={rowsCount}
                    columns={tableColumns}
                    editMode="row"
                    columnHeaderHeight={30}
                    rowHeight={30}
                    checkboxSelection={false}
                    disableRowSelectionOnClick {...rows}
                    // hideFooter={true}
                    // hideFooterRowCount={true}
                    onRowClick={handleRowClick}
                    loading={isLoading}
                    paginationMode="server"
                        pagination={true}
                        paginationModel={paginationModel}
                        pageSizeOptions={[10, 25, 50]}
                        onPaginationModelChange={(params) => { handlePageChange(params) }}
                        initialState={{
                            pagination: { paginationModel: { pageSize: paginationModel.pageSize, page: paginationModel.page } },
                        }}
                        sortingOrder={['desc', 'asc']}
                        sortingMode="server"
                        onSortModelChange={handleSortChange}

                />
              
            </Box>
        </TableContainer>
    )

}