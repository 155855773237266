import {
    DataGridPro,
    DEFAULT_GRID_AUTOSIZE_OPTIONS
} from '@mui/x-data-grid-pro';
import { OrdersWrapper, StyledSceneContent } from "../../components/styledComponents";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router'

import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { deleteViewElastic, getAllOrders, getAllOrdersElastic, getAllSearchFieldsElastic, getAllUserViewsExtendedSearch, searchOrdersElastic } from "../../store/actions/orderActions";
import SaveView from '../../components/SaveView/SaveView'
import Filter from '../../components/Filter/Filter'

import filtericon from '../../assets/icons/filter_icon.png'
import DeleteIcon from '@mui/icons-material/Delete'
import './ElasticStyle.css'


import { InputAdornment, Box, Paper, Typography, Button, Popover, List, ListItem, ListItemButton, ListItemText, Divider } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import { deleteItem, getItem, setItem } from "../../utils/cookie";



const actionStyles = { display: 'flex', width: '100%', padding: '1em', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }


const defaultView = {
    "status": true,
    "orderType": true,
    "controlNumber": true,
    "shipperAgentCode": false,
    "shipperAgent": true,
    "unitNumber": true,
    "secondaryUnit": false,
    "chassisNumber": true,
    "referenceNumber": true,
    "releaseNumber": true,
    "shipper": true,
    "stopStatus": true,
    "stopNumber": true,
    "stops.code": false,
    "stops.name": true,
    "stops.address": false,
    "stops.city": false,
    "stops.state": false,
    "stops.zip": false,
    "stops.phone": false,
    "stops.apptDate": true,
    "stops.apptTime": true,
    "stops.pieces": false,
    "stops.weight": false,
    "stops.commodity": false,
    "stops.doorSeal": false,
    "stops.arriveDate": false,
    "stops.arriveTime": false,
    "stops.spotDate": false,
    "stops.spotTime": false,
    "stops.unloadDate": false,
    "stops.unloadTime": false,
    "stops.departDate": false,
    "stops.departTime": false,
    "stops.availDate": false,
    "stops.availTime": false,
    "stops.arriveTruck": false,
    "stops.arriveDriver": false,
    "stops.departTruck": false,
    "stops.departDriver": false,
    "stops.driverNotes": false,
    "stops.driverNotes2": false,
    "stops.etaDate": false,
    "stops.etaTime": false,
    "stops.shipperBillOfLadding": false,
    "stops.pickupNumber": false,
    "createdDate": true,
    "billingDate": true,
    "billingBooking": false,
    "poruPoint": false,
    "disposition": false,
    "dispositionCChassisPickupPoint": false,
    "dispositionCChassisDisposition": false,
    "etaDate": false,
    "etaTime": false,
    "notifyDate": false,
    "notifyTime": false,
    "cuttOffDate": false,
    "cutOffTime": false,
    "pickDate": false, // Last/Diem Day
    "hazMat": false,
    "equipSize": false,
    "vessel": false,
    "voyageNumber": false,
    "dispositionCDestinationPort": false,
    "shipperOrConsignee": false,
    "consignee": false,
    "consigneeAddress": false,
    "consigneeCity": false,
    "consigneeState": false,
    "consigneeZip": false,
    "consigneePhone": false,

}

const ElasticDashBoard = () => {
    const dispatch = useDispatch()
    const history = useHistory();

    const [anchorEl, setAnchorEl] = useState(null);
    const [showFilter, setShowFilter] = useState(false);
    const [showColumnFilter, setColumnFilter] = useState(false);
    const [selectedView, setSelectedView] = useState('')
    const [showDeleteViewButton, setShowDeleteViewButton] = useState(false)
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)

    const [searchClause, setSearchClause] = useState([
        { clause: '', field: '', operand: '', value: '' }
    ])

    const [view, setView] = useState(defaultView)

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0,
    });

    const [elasticFilter, setElasticFilter] = useState([])

    const [sortModel, setSortModel] = useState({
        sortBy: 'controlNumber',
        sortOrder: 'desc',
    });

    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );

    const userViews = useSelector(
        ({ orderReducer }) => orderReducer.allExtendedSearchViews
    );


    const newOrderClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popOverID = open ? "new-order-popover" : undefined;

    // const allOrders = data //passed static data
    const allOrders = useSelector(({ orderReducer }) => orderReducer.allElasticOrders)
    const searchOptions = useSelector(({ orderReducer }) => orderReducer.searchFieldsRes)
    console.log('All orders', allOrders)


    const [searchText, setSearchText] = useState('')
    const [clearBtn, setClearBtn] = useState(false)
    const [openSaveView, setOpenSave] = useState(false);

    // Column auto resizing properties
    const [includeHeaders, setIncludeHeaders] = useState(
        DEFAULT_GRID_AUTOSIZE_OPTIONS.includeHeaders,
    );
    const [includeOutliers, setExcludeOutliers] = useState(
        DEFAULT_GRID_AUTOSIZE_OPTIONS.includeOutliers,
    );
    const [outliersFactor, setOutliersFactor] = useState(
        String(DEFAULT_GRID_AUTOSIZE_OPTIONS.outliersFactor),
    );
    const [expand, setExpand] = useState(DEFAULT_GRID_AUTOSIZE_OPTIONS.expand);

    useEffect(() => {
        // const cachedFilter = getItem('AppliedElasticFilter')
        if (ordersLocation) {
            // if (cachedFilter) {
            //     handleApplyFilter(cachedFilter)
            //     setSearchClause(cachedFilter)
            //     setShowDeleteViewButton(true)
            // }
            // else {
            dispatch(getAllOrdersElastic({ locationNumber: ordersLocation.locationNumber, search: null }))
            // }
        }
        dispatch(getAllSearchFieldsElastic({}))
        dispatch(getAllUserViewsExtendedSearch({})).then(res => console.log('RESPONSE FOR VIEWS', res))
        setSearchClause([{
            clause: { label: 'AND' },
            field: { fieldName: 'locationNumber', format: "Number", label: "Location" },
            operand: { label: 'IS' },
            value: (typeof ordersLocation?.locationNumber == "number") ? ordersLocation?.locationNumber.toString() : ordersLocation?.locationNumber || '0'
        }])
        setElasticFilter([{
            clause: 'AND',
            field: 'locationNumber',
            operand: 'IS',
            value: ordersLocation?.locationNumber || 0
        }])
    }, [ordersLocation])

    const handleSearch = () => {
        // console.log('Searching For', searchText)
        // dispatch(getAllOrders({ locationNumber: ordersLocation.locationNumber, search: searchText }))
        const formattedSearchStr = searchText.trimEnd();
        if (ordersLocation) {
            let searchClause = [
                {
                    clause: { label: 'AND' },
                    field: { fieldName: 'locationNumber', format: "Number", label: "Location" },
                    operand: { label: 'IS' },
                    value: ordersLocation?.locationNumber || 0
                },
                {
                    "clause": { "label": "OR" },
                    "field": { "fieldName": "controlNumber", "format": "Number", "label": "Control #" },
                    "operand": { "label": "IS" }, "value": (!isNaN(formattedSearchStr)) ? formattedSearchStr : '-1'
                },
                {
                    "clause": { "label": "OR" },
                    "field": { "fieldName": "shipperAgent", "format": "Alphanumeric", "label": "Order By" },
                    "operand": { "label": "IS" }, "value": `${formattedSearchStr}`
                },
                {
                    "clause": { "label": "OR" },
                    "field": { "fieldName": "unitNumber", "format": "Alphanumeric", "label": "Unit #" },
                    "operand": { "label": "IS" }, "value": `${formattedSearchStr}`
                }, {
                    "clause": { "label": "OR" },
                    "field": { "fieldName": "billingBooking", "format": "Alphanumeric", "label": "Booking" },
                    "operand": { "label": "IS" }, "value": `${formattedSearchStr}`
                }, {
                    "clause": { "label": "OR" },
                    "field": { "fieldName": "billingReference", "format": "Alphanumeric", "label": "Rerference #" },
                    "operand": { "label": "IS" }, "value": `${formattedSearchStr}`
                }]
            handleApplyFilter(searchClause);
        }
    }

    const clearSearch = () => {
        setElasticFilter([{
            clause: 'AND',
            field: 'locationNumber',
            operand: 'IS',
            value: ordersLocation?.locationNumber || 0
        }])
        dispatch(getAllOrdersElastic({ locationNumber: ordersLocation.locationNumber, search: null, query: { page: 0, pageSize: 100 } }))
    }
    const openOrder = (params) => {
        console.log(params.row)
        history.push(`/orders/${params.row.locationNumber}/${params.row.controlNumber}`)
    }
    const handleClickOpen = () => {
        setOpenSave(true);
    };
    const handleCloseSave = (value) => {
        setOpenSave(false);
    };
    const handleFilter = () => {
        setShowFilter(true);
    };

    const handleApplyFilter = (searchClause) => {
        if (ordersLocation) {
            // Local cache filter for 7 days validity, unless user changes
            // setItem('AppliedElasticFilter', searchClause, 7)
            let wildCardFields = [
                'shipperAgentCode', 'shipperAgent', 'unitNumber', 'chassisNumber', 'billingBooking',
                'billingReference', 'secondaryUnit',
                'voyageNumber', 'equipSize', 'shipperOrConsignee', 'poruPoint', 'disposition', 'dispositionChassisPickupPoint',
                'dispositionChassisDisposition', 'orderType', 'releaseNumber', 'shipper',
                'stopStatus', 'stops.code', 'stops.name', 'stops.address', 'stops.city',
                'stops.state', 'stops.phone', 'stops.commodity', 'stops.doorSeal', 'stops.shipperBillOfLadding',
                'dispositionCDestinationPort', 'consignee', 'consigneeAddress', 'consigneeCity', 'consigneeState',
                'consigneePhone'
            ];
            let filter = searchClause.map(item => ({
                clause: item.clause.label,
                field: item.field.fieldName,
                operand: item.operand.label,
                value: wildCardFields.includes(item.field.fieldName) ? `${item.value}*` : item.value,
                format: item.field.format
            }))
            // filter.push({
            //     clause: 'AND',
            //     field: 'locationNumber',
            //     operand: 'IS',
            //     value: ordersLocation.locationNumber,
            // })

            // let isNested = filter.filter(item => (item.field.toString().includes('.')))
            // if(isNested.length > 0){
            //     let nestedKeys = isNested.map(item => item.field.split('.')[0])
            //     nestedKeys = nestedKeys.filter((value, index, array) => {
            //         return array.indexOf(value) === index;
            //       })
            //     console.log('Nested Keys', nestedKeys)
            //     let nestedObject = {}
            //     let nestedClauses = nestedKeys.map(key => filter.filter(item => (item.field.toString().startsWith(key))))
            //     nestedClauses.forEach((item, index) => {
            //         nestedObject[nestedKeys[index]] = item
            //     })
            //     console.log('Nested Object', nestedObject)
            //     const notNested = filter.filter(item => (!item.field.toString().includes('.')))
            //     nestedObject[nestedKeys[0]] = [
            //         ...notNested,
            //         ...nestedObject[nestedKeys[0]],
            //     ]
            //     filter = {
            //         "nested": nestedObject
            //     }
            // }
            console.log('FILTERING', filter)
            setElasticFilter(filter);
            dispatch(searchOrdersElastic(filter))
        }
    }

    const loadUserView = (event, value) => {
        console.log('Loading View', value)
        if (!value) {
            // Default
            setView(defaultView)
            // setSearchClause([
            //     { clause: '', field: '', operand: '', value: '' }
            // ])
            setSearchClause([{
                clause: { label: 'AND' },
                field: { fieldName: 'locationNumber', format: "Number", label: "Location" },
                operand: { label: 'IS' },
                value: (typeof ordersLocation?.locationNumber == "number") ? ordersLocation?.locationNumber.toString() : ordersLocation?.locationNumber || '0'
            }])
            setShowDeleteViewButton(false)
            setSelectedView('')
            // deleteItem('AppliedElasticFilter')
            if (ordersLocation) {
                dispatch(getAllOrdersElastic({ locationNumber: ordersLocation.locationNumber, search: null }))
            }
        }
        else {
            setSearchClause(value.searchClause)
            handleApplyFilter(value.searchClause)
            setView(value.view)
            setShowDeleteViewButton(true)
            console.log(value)
            setSelectedView(value)
        }
        // if (ordersLocation)
        //     dispatch(searchOrdersElastic(value.searchClause))

    }

    const handleColumnFilter = () => {
        setColumnFilter(true);
    }

    const formatDate = (value) => {
        if (value) {
            value = value.toString()
            return `${value.substring(3, 5)}/${value.substring(5)}/${value.substring(1, 3)}`
        }
        return ''
    }

    const formatFilterPayload = (searchClause) => {
        let wildCardFields = [
            'shipperAgentCode', 'shipperAgent', 'unitNumber', 'chassisNumber', 'billingBooking',
            'billingReference', 'secondaryUnit',
            'voyageNumber', 'equipSize', 'shipperOrConsignee', 'poruPoint', 'disposition', 'dispositionChassisPickupPoint',
            'dispositionChassisDisposition', 'orderType', 'releaseNumber', 'shipper',
            'stopStatus', 'stops.code', 'stops.name', 'stops.address', 'stops.city',
            'stops.state', 'stops.phone', 'stops.commodity', 'stops.doorSeal', 'stops.shipperBillOfLadding',
            'dispositionCDestinationPort', 'consignee', 'consigneeAddress', 'consigneeCity', 'consigneeState',
            'consigneePhone'
        ];
        let filter = searchClause.map(item => ({
            clause: item.clause.label,
            field: item.field.fieldName,
            operand: item.operand.label,
            value: wildCardFields.includes(item.field.fieldName) ? `${item.value}*` : item.value,
            format: item.field.format
        }))
        return filter;
    }
    const handlePageChange = (params) => {
        setPaginationModel({ pageSize: params.pageSize, page: params.page })
        dispatch(searchOrdersElastic(elasticFilter, { query: { page: params.page, pageSize: params.pageSize, sortBy: sortModel.sortBy, sortOrder: sortModel.sortOrder } }))
        //dispatch(getAllOrdersElastic({ locationNumber: ordersLocation.locationNumber, search: null, query: { page: params.page, pageSize: params.pageSize, sortBy: sortModel.sortBy, sortOrder: sortModel.sortOrder } }))
    }
    const sortChange = (event) => {
        if (event.length > 0) {
            setSortModel({ sortBy: event[0].field, sortOrder: event[0].sort });
            //console.log('====1', elasticFilter)
            //console.log('====2', searchClause)
            //let searchCriteria = [...elasticFilter, searchClause];
            dispatch(searchOrdersElastic(elasticFilter, { query: { page: paginationModel.page, pageSize: paginationModel.pageSize, sortBy: event[0].field, sortOrder: event[0].sort } }))
            //dispatch(getAllOrdersElastic({ locationNumber: ordersLocation.locationNumber, search: null, query: { page: paginationModel.page, pageSize: paginationModel.pageSize, sortBy: event[0].field, sortOrder: event[0].sort } }))
        }
    };

    const columns = [
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 50,
            flex: 1,

        },
        {
            field: 'orderType',
            headerName: 'Type',
            minWidth: 50,
            flex: 1,

        },
        {
            field: 'controlNumber',
            headerName: 'Control #',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={`/orders/${params.row.locationNumber}/${params.row.controlNumber}`} target='_blank' >{params.row.controlNumber}</a>
                )
            }
        },
        {
            field: 'shipperAgentCode',
            headerName: 'Order By Code',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'shipperAgent',
            headerName: 'Order By',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'unitNumber',
            headerName: 'Unit #',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'secondaryUnit',
            headerName: 'Secondary Unit #',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'chassisNumber',
            headerName: 'Chassis',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'billingReference',
            headerName: 'Rerference #',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'releaseNumber',
            headerName: 'Release#',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'shipper',
            headerName: 'Shipper/Consignee',
            //type: 'number',
            minWidth: 110,
            flex: 1,
            renderCell: (params) => (typeof params.row.orderType !== 'undefined' && params.row.orderType === 'EXPORT') ? params.row.shipperOrConsignee : params.row.shipper
        },
        {
            field: 'stopStatus',
            headerName: 'Stop Status',
            type: 'number',
            minWidth: 110,
            flex: 1
        },
        {
            field: 'stopNumber',
            headerName: 'Stop #',
            minWidth: 110,
            flex: 1
        },
        {
            field: 'stops.code',
            headerName: 'Stop Shipper/Consignee Code',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.name',
            headerName: 'Stop Shipper/Consignee',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.address',
            headerName: 'Stop Address',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.city',
            headerName: 'Stop City',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.state',
            headerName: 'Stop State',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.zip',
            headerName: 'Stop Zip',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.phone',
            headerName: 'Stop Phone',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.apptDate',
            headerName: 'Appt Date',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                return (
                    <p>{formatDate(params.row["stops.apptDate"])}</p>
                )
            }
        },
        {
            field: 'stops.apptTime',
            headerName: 'Appt Time',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.pieces',
            headerName: 'Stop Pieces',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.weight',
            headerName: 'Stop Weight',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.commodity',
            headerName: 'Stop Commodity',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.doorSeal',
            headerName: 'Stop Door Seal',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.arriveDate',
            headerName: 'Arrive Date',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                return (
                    <p>{formatDate(params.row["stops.arriveDate"])}</p>
                )
            }
        },
        {
            field: 'stops.arriveTime',
            headerName: 'Arrive Time',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.spotDate',
            headerName: 'Spot Date',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                return (
                    <p>{formatDate(params.row["stops.spotDate"])}</p>
                )
            }
        },
        {
            field: 'stops.spotTime',
            headerName: 'Spot Time',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.unloadDate',
            headerName: 'Unload Date',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                return (
                    <p>{formatDate(params.row["stops.unloadDate"])}</p>
                )
            }
        },
        {
            field: 'stops.unloadTime',
            headerName: 'Unload Time',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.departDate',
            headerName: 'Depart Date',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                return (
                    <p>{formatDate(params.row["stops.departDate"])}</p>
                )
            }
        },
        {
            field: 'stops.departTime',
            headerName: 'Depart Time',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.availDate',
            headerName: 'Avail Date',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                return (
                    <p>{formatDate(params.row["stops.availDate"])}</p>
                )
            }
        },
        {
            field: 'stops.availTime',
            headerName: 'Avail Time',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.arriveTruck',
            headerName: 'Arrive Truck',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.arriveDriver',
            headerName: 'Arrive Driver',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.departTruck',
            headerName: 'Depart Truck',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.departDriver',
            headerName: 'Depart Driver',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.driverNotes',
            headerName: 'Driver Notes 1',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.driverNotes2',
            headerName: 'Driver Notes 2',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.etaDate',
            headerName: 'Stop ETA Date',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                return (
                    <p>{formatDate(params.row["stops.etaDate"])}</p>
                )
            }
        },
        {
            field: 'stops.etaTime',
            headerName: 'Stop ETA Time',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.shipperBillOfLadding',
            headerName: 'Stop Bill Of Ladding',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.pickupNumber',
            headerName: 'Stop PO/PU',
            minWidth: 160,
            flex: 1
        },


        {
            field: 'createdDate',
            headerName: 'Created',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                return (
                    <p>{formatDate(params.row.createdDate)}</p>
                )
            }
        },
        {
            field: 'billingDate',
            headerName: 'Billed Date',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                return (
                    <p>{formatDate(params.row.billingDate)}</p>
                )
            }
        },
        {
            field: 'billingBooking',
            headerName: 'Booking #',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'poruPoint',
            headerName: 'Pickup Location',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'disposition',
            headerName: 'Delivery Location',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'dispositionCChassisPickupPoint',
            headerName: 'Chassis Pickup Location',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'dispositionCChassisDisposition',
            headerName: 'Chassis Del Location',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'etaDate',
            headerName: 'ETA Date',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                return (
                    <p>{formatDate(params.row.etaDate)}</p>
                )
            }
        },
        {
            field: 'etaTime',
            headerName: 'ETA Time',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'notifyDate',
            headerName: 'Notify Date',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                return (
                    <p>{formatDate(params.row.notifyDate)}</p>
                )
            }
        },
        {
            field: 'notifyTime',
            headerName: 'Notify Time',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'cuttOffDate',
            headerName: 'Cutt Off Date',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                return (
                    <p>{formatDate(params.row.cuttOffDate)}</p>
                )
            }
        },
        {
            field: 'cutOffTime',
            headerName: 'Cutt Off Time',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'pickDate',
            headerName: 'Last/Diem Date',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                return (
                    <p>{formatDate(params.row.pickDate)}</p>
                )
            }
        },
        {
            field: 'hazMat',
            headerName: 'Hazmat',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'equipSize',
            headerName: 'Equipment Size',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'vessel',
            headerName: 'Vessel',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'voyageNumber',
            headerName: 'Voyage',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'dispositionCDestinationPort',
            headerName: 'Destination Port',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'shipperOrConsignee',
            headerName: 'Consignee',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'consignee',
            headerName: 'Consignee Name',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'consigneeAddress',
            headerName: 'Consignee Address',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'consigneeCity',
            headerName: 'Consignee City',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'consigneeState',
            headerName: 'Consignee State',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'consigneeZip',
            headerName: 'Consignee Zip',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'consigneePhone',
            headerName: 'Consignee Phone',
            minWidth: 160,
            flex: 1,
        },

    ];


    return (
        <OrdersWrapper>
            {/* <SceneHeader title="Orders"> */}
            <div style={actionStyles}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '50%', marginTop: '1rem', alignItems: 'center' }}>

                    <TextField
                        label="Search Orders ... "
                        size="small"
                        value={searchText}
                        onChange={(event) => { setSearchText(event.target.value) }}
                        onKeyPress={(event) => { event.key === 'Enter' && handleSearch(); setClearBtn(true); }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment>
                                    {(clearBtn === true) ? (
                                        <IconButton onClick={() => { setClearBtn(false); setSearchText(''); clearSearch() }}>
                                            <ClearIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton onClick={() => { setClearBtn(true); handleSearch() }} title='Search By Control, Unit, Ref, OrderBy, Booking '>
                                            <SearchIcon />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            )
                        }}
                    />
                    <div style={{ width: '30px', height: '30px', backgroundColor: '#D9E7F7', borderRadius: '3px', marginLeft: '0.5rem', alignItems: 'center', display: 'flex', justifyContent: 'center' }} onClick={handleFilter}>
                        <img src={filtericon} style={{ width: '18px', height: '18px' }} />
                    </div>
                    <Filter
                        showFilter={showFilter}
                        setShowFilter={setShowFilter}
                        handleFilter={handleApplyFilter}
                        searchFields={searchOptions}
                        searchClause={searchClause}
                        setSearchClause={setSearchClause}
                        loadDefaultView={loadUserView}
                    />



                    {/* <Button
                        startIcon={<img src={addsearchicon} style={{ width: '18px', height: '18px' }} />}
                        variant="text"
                        style={{ textDecorationLine: 'underline', textTransform: 'capitalize', color: '#0B4387', marginLeft: '0.5rem' }}
                    >Search
                    </Button> */}

                    <Button
                        variant="text"
                        onClick={handleClickOpen}
                        style={{ textDecorationLine: 'underline', textTransform: 'capitalize', color: '#0B4387' }}
                    >Save
                    </Button>
                    <SaveView
                        open={openSaveView}
                        onClose={handleCloseSave}
                        searchClause={searchClause}
                        view={view}
                    />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', width: '50%', justifyContent: 'end', marginTop: '1rem' }}>

                    <Button
                        variant="text"
                        style={{ textDecorationLine: 'underline', textTransform: 'capitalize', color: '#0B4387' }}
                    >Export
                    </Button>

                    <Button onClick={newOrderClick} style={{ marginRight: '0.5rem', backgroundColor: '#0B4387' }} size="small" variant="contained"> NEW ORDER </Button>
                    <Popover
                        id={popOverID}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                        }}
                    >
                        <List>
                            <ListItem disablePadding divider>
                                <ListItemButton component="a" href={`/orders/${ordersLocation?.locationNumber}/new/INBOUND`} dense>
                                    <ListItemText primary="INBOUND" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding divider>
                                <ListItemButton component="a" href={`/orders/${ordersLocation?.locationNumber}/new/OUTBOUND`} dense>
                                    <ListItemText primary="OUTBOUND" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding divider>
                                <ListItemButton component="a" href={`/orders/${ordersLocation?.locationNumber}/new/IMPORT`} dense>
                                    <ListItemText primary="IMPORT" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding divider>
                                <ListItemButton component="a" href={`/orders/${ordersLocation?.locationNumber}/new/EXPORT`} dense>
                                    <ListItemText primary="EXPORT" />
                                </ListItemButton>
                            </ListItem>

                        </List>
                    </Popover>
                    {/* 
                    <Button style={{ marginRight: '0.5rem', backgroundColor: '#0B4387' }} size="small" variant="contained">Copy Order </Button>
                    <Button style={{ marginRight: '0.5rem', backgroundColor: '#0B4387' }} size="small" variant="contained">Copy to Template</Button> */}
                </div>
            </div>
            {/* </SceneHeader> */}
            {/* <StyledSceneContent> */}

            <Paper sx={{ display: 'flex', flexDirection: "column", flex: 1, padding: '1rem', alignItems: 'right', maxHeight: '60%' }}>
                <div style={{ width: '100%', backgroundColor: '#0B4387', height: '55px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', alignItems: 'center', display: 'flex', justifyContent: 'end' }}>
                    <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'end', display: 'flex', paddingRight: '2rem' }}>
                        <label style={{ color: 'white', paddingRight: '1rem' }}>View :</label>
                        <Autocomplete
                            disablePortal
                            className="autocomplestyl"
                            id="combo-box-demo"
                            value={selectedView || ""}
                            options={userViews ? userViews.data : []}
                            getOptionLabel={(option) => option ? `${decodeURIComponent(option.name)}` : ""}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                        <div style={{ "display": "flex", "flexDirection": "row", "justifyContent": "space-between" }}>
                                            <p>{`${decodeURIComponent(option.name)}`}</p>
                                        </div>
                                    </li>
                                )
                            }}
                            isOptionEqualToValue={(option, value) => option.name === value.name || option === ""}
                            sx={{ width: 200, backgroundColor: 'white' }}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={loadUserView}
                        />
                        {
                            showDeleteViewButton && (
                                <Button onClick={() => {
                                    setIsConfirmDialogOpen(true)
                                }}>
                                    <DeleteIcon style={{ color: 'white' }} />
                                </Button>

                            )
                        }
                        <ConfirmationDialog
                            isConfirmDialogOpen={isConfirmDialogOpen}
                            title={`Delete View`}
                            description={`Are you sure you want to delete view ${selectedView.name} ?`}
                            acceptTxt="Yes"
                            rejectTxt="No"
                            handleNo={() => setIsConfirmDialogOpen(false)}
                            handleYes={() => {
                                console.log('Delete = Yes')
                                dispatch(deleteViewElastic(selectedView)).then(res => {
                                    console.log('RESPONSE FOR DELETE VIEWS', res)
                                    setIsConfirmDialogOpen(false)
                                    loadUserView('')
                                    dispatch(getAllUserViewsExtendedSearch({}))
                                })
                            }}
                        />

                    </div>
                </div>
                {/* <div style={{ width: '100%', backgroundColor: '#DCDCDC', height: '40px', alignItems: 'center', display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <img src={sorticon} style={{ marginLeft: '15px', width: '15px', height: '15px' }} />
                        <Typography variant="h6" style={{ color: 'black', fontWeight: 'bold', fontSize: '16px', marginLeft: '0.5rem' }} > {`Sort`} </Typography>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={handleColumnFilter}>
                        <img src={columnfiltericon} style={{ marginLeft: '15px', width: '15px', height: '15px' }} />
                        <Typography variant="h6" style={{ color: 'black', fontWeight: 'bold', fontSize: '16px', marginLeft: '0.5rem' }} > {`Column`} </Typography>
                    </div>

                    <ColumnFilter
                        showColumnFilter={showColumnFilter}
                        setColumnFilter={setColumnFilter}
                    />


                </div> */}
                <Box
                    sx={{
                        height: window.innerHeight - Number(265),
                        width: '100%',
                        '& .actions': {
                            color: 'text.secondary',
                        },
                        '& .textPrimary': {
                            color: 'text.primary',
                        },
                        '& .tableTH': {
                            fontWeight: 700
                        },
                        '& .non-editable': {
                            backgroundColor: "#69696929 !important"
                        },
                    }}
                >
                    <DataGridPro
                        autoHeight={false}
                        rows={allOrders.data}
                        rowCount={allOrders.totalRows}
                        columns={columns}
                        columnVisibilityModel={view}
                        loading={allOrders.isLoading}
                        // getRowId={(row) => `${row.locationNumber}-${row.controlNumber}-${row.stopNumber}`}
                        getRowId={(row) => `${Math.random()}-${row.controlNumber}`}
                        // onRowClick={openOrder}
                        onColumnVisibilityModelChange={(model, details) => {
                            console.log("model", model);
                            setView(model)
                        }}
                        paginationMode="server"
                        pagination={true}
                        pageSizeOptions={[10, 25, 50]}
                        onPaginationModelChange={(params) => { handlePageChange(params) }}
                        sortingOrder={['desc', 'asc']}
                        sortingMode="server"
                        onSortModelChange={sortChange}
                        initialState={{
                            pagination: { paginationModel: { pageSize: paginationModel.pageSize, page: paginationModel.page } },
                            sortModel: [
                                {
                                    field: 'controlNumber',
                                    sort: 'desc',
                                },
                            ],
                        }}

                        //paginationModel={paginationModel}
                        //onPaginationModelChange={setPaginationModel}
                        autosizeOptions={{
                            includeHeaders,
                            includeOutliers,
                            outliersFactor: Number.isNaN(parseFloat(outliersFactor))
                                ? 1
                                : parseFloat(outliersFactor),
                            expand,
                        }}
                    />
                </Box>
            </Paper>
            {/* </StyledSceneContent> */}
        </OrdersWrapper>
    );
};

export default ElasticDashBoard;