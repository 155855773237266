import React, { useContext } from "react";
import { TextField, Paper, FormControlLabel, FormGroup } from "@mui/material";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Checkbox from '@mui/material/Checkbox'
import './Styles.css'
import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GET } from '../../../utils/axios'
import orderContext from '../orderContext';

export default function DriverNotes(props) {

    const { billingControlRef, disableFull } = props
    const dispatch = useDispatch();

    // Local State for Select Box of Order By
    // const [equipmentState, setEquipmentState] = useState(billingControlRef.current.equipmentDetails)
    const [driverNotesState, setDriverNotes] = useState(billingControlRef.current.driverNotes)
    const { setIsStateChanged } = useContext(orderContext);
    const handleFieldChanges = () => {
        /**
         * 28-09-2023
         * update global context that field has changed
         */
        console.log('driver notes fields are changes')
        setIsStateChanged(true);
        /** END */
    }

    return (
        <div className="mainContainer drivernotes" >
            <Divider
                textAlign="left"
                className="dividersty"
            >
                <Chip label="Driver Notes" size="small" variant="outlined" />
            </Divider>
            <Paper className="paperstyl" variant="outlined">
                <table className="width_100" style={{ alignSelf: 'start' }}>
                    <tbody className="width_100" >
                        <tr style={{ paddingTop: 20 }}>
                            <td >
                                <TextField
                                    label="Driver Notes 1"
                                    size="small"
                                    fullWidth
                                    value={driverNotesState?.driverNotes || ''}
                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 40 }}
                                    multiline
                                    rows={4}
                                    onChange={(event) => {
                                        console.log(event.target.value)
                                        setDriverNotes({
                                            ...driverNotesState,
                                            driverNotes: event.target.value
                                        })
                                        billingControlRef.current = {
                                            ...billingControlRef.current,
                                            driverNotes: {
                                                ...driverNotesState,
                                                driverNotes: event.target.value
                                            }
                                        }
                                        handleFieldChanges();
                                    }}
                                    disabled={disableFull}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ paddingTop: 20 }}>
                                <TextField
                                    label="Driver Notes 2"
                                    size="small"
                                    fullWidth
                                    value={driverNotesState?.driverNotes2 || ''}
                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 40 }}
                                    multiline
                                    rows={4}
                                    onChange={(event) => {
                                        console.log(event.target.value)
                                        setDriverNotes({
                                            ...driverNotesState,
                                            driverNotes2: event.target.value
                                        })
                                        billingControlRef.current = {
                                            ...billingControlRef.current,
                                            driverNotes: {
                                                ...driverNotesState,
                                                driverNotes2: event.target.value
                                            }
                                        }
                                        handleFieldChanges();
                                    }}
                                    disabled={disableFull}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Paper>
        </div>
    );
}