import { useState, useEffect, useContext } from "react";
import {
    Table,
    TableHead,
    TableRow,
    Paper,
    TableContainer,
    TextField,
    Button,
    TableCell
} from "@mui/material";

// Icons 
import AddCircle from '@mui/icons-material/AddCircle';
import RemoveCircle from '@mui/icons-material/RemoveCircle';

// import common functions
import {
    calcTotalRevenue,
    calcTotalExpense,
    calcPTNet,
    calcTotalPTNet,
    calcTotalRevenueExcludingUnPayableGL,
    calcPTPercentage,
    calcTotalPercentage
} from './CommonFn';

// import linehaul table
import LinehaulRAC from './LinehaulRAC';
// import Accessorial table
import AccessorialRAC from './AccessorialRAC';

// Styling 
import './RatesAndChargesStyles.css'

import { getGLCodes } from "../../../store/actions/rateBookActions";
import { useDispatch, useSelector } from "react-redux";
import { GET } from '../../../utils/axios'
import orderContext from '../orderContext';
// import custom date picker
import CustomDatePicker from '../../../components/CustomDatePicker';
import PdfGenerator from "../../../components/PdfGenerator/PdfGenerator";
import { useParams } from "react-router-dom";
import Loader from '../../../components/Loader/Lodaer';

const tableColumns = [
    { id: 'modify', label: 'Modify' },
    { id: 'rateName', label: 'Rate Name' },
    { id: 'GLCode', label: 'GL Code' },
    { id: 'InvoiceDescr', label: 'Invoice Descr' },
    { id: 'Rate', label: 'Rate ($)' },
    { id: 'Unit', label: 'Units' },
    { id: 'Amount', label: 'Amount ($)' },
    { id: 'payableDescr', label: 'Payable Descr' },
    { id: 'payableGLCode', label: 'GL Code' },
    { id: 'payableRate', label: 'Rate ($)' },
    { id: 'payableUnit', label: 'Units' },
    { id: 'payableAmount', label: 'Amount ($)' },
    { id: 'Truck', label: 'Truck' },
    { id: 'PTDate', label: 'PT Date' },
    { id: 'actions', label: 'Actions' },
]

export default function RatesAndCharges(props) {
    const { ordersLocation, disableFull, linehaulRef, accessorialRef, orderType, billingControlRef, snackBarHandler, isTemplateScreen } = props;
    const [linehaulEditRef, setLinehaulEditRef] = useState(linehaulRef);
    const [accessorialEditRef, setAccessorialEditRef] = useState(accessorialRef);
    const rateBookReducer = useSelector((rateBookReducer) => rateBookReducer.rateBookReducer);
    const [billingDate, setBillingDate] = useState(billingControlRef.current.orderBy?.billingDate || '')
    const [edi210State, setEdi210State] = useState('N');
    const [isBillingDateLocked, setIsBillingDateLocked] = useState(billingControlRef.current.orderBy?.isBillingDateLocked || false)
    const [addNewRowBtnLH, setAddNewRowBtnLH] = useState(false)
    const [addNewRowBtnAcc, setAddNewRowBtnAcc] = useState(false)
    const [addOnlyPayables, setAddOnlyPayables] = useState(false)
    const [addOnlyReceivables, setAddOnlyReceivables] = useState(false)
    const [addOnlyPayablesAcc, setAddOnlyPayablesAcc] = useState(false)
    const [addOnlyReceivablesAcc, setAddOnlyReceivablesAcc] = useState(false)
    const [unPayableGLCodes, setUnPayableGLCodes] = useState([])
    const [FSCPercentage, setFSCPercentage] = useState({
        fsc_per_r: 0,
        fsc_per_b: 0,
        fsc_per_c: 0
    })
    const [finalTotals, setFinalTotals] = useState({
        revenue: 0,
        expense: 0,
        PTNet: 0,
        totalPTNet: 0,
        PTPercentage: 0,
        totalPercentage: 0,
    })
    const [invoiceLoader, setInvoiceLoader] = useState(false)

    const dispatch = useDispatch();
    const params = useParams();
    const { setIsStateChanged, isEdi210 } = useContext(orderContext);
    const handleFieldChanges = () => {
        /**
         * 28-09-2023
         * update global context that field has changed
         */
        console.log('rates & charges fields are changed')
        setIsStateChanged(true);
        /** END */
    }

    const handleBillingDate = (val) => {
        handleFieldChanges()
        setBillingDate(val);
        billingControlRef.current = {
            ...billingControlRef.current,
            orderBy: {
                ...billingControlRef.current.orderBy,
                billingDate: val
            }
        }
    }

    useEffect(() => {
        if (rateBookReducer.GLCodeData.length > 0) {
            getFSCPercentage();
            upPayabaleGLCodes(rateBookReducer.GLCodeData);
        }
    }, [rateBookReducer.GLCodeData])

    useEffect(() => {
        calcBottomTotals();
        console.log('RatesCharges - Render component for bottom calculation');
    }, [unPayableGLCodes])

    const getFSCPercentage = async () => {
        if (ordersLocation !== "" && ordersLocation !== undefined) {
            const response = await GET(`/fuelsurcharge/${ordersLocation}`)
            if (response?.status === 'success') {
                let item = response?.data
                setFSCPercentage({
                    fsc_per_r: item?.fsc_per_r || 0,
                    fsc_per_b: item?.fsc_per_b || 0,
                    fsc_per_c: item?.fsc_per_c || 0
                });
            }
        }
    }

    const upPayabaleGLCodes = (glcodes) => {
        let unPayableGLCodes = [];
        glcodes.map(e => {
            if (e.glPayRec === 'R') {
                unPayableGLCodes.push(e.glcode);
            }
        })
        setUnPayableGLCodes(unPayableGLCodes);
    }
    const calcBottomTotals = () => {
        const commonArr = [...linehaulEditRef.current, ...accessorialEditRef.current];

        let revenue = calcTotalRevenue(commonArr) || 0;
        let revenueByExcludingGL = calcTotalRevenueExcludingUnPayableGL(commonArr, unPayableGLCodes) || 0;
        let expense = calcTotalExpense(commonArr);
        let PTNet = calcPTNet(revenueByExcludingGL, expense);
        let totalPTNet = calcTotalPTNet(revenue, expense);
        let PTPercentage = calcPTPercentage(revenueByExcludingGL, expense);
        let totalPercentage = calcTotalPercentage(revenue, expense);

        setFinalTotals({
            revenue: revenue,
            expense: expense,
            PTNet: PTNet,
            totalPTNet: totalPTNet,
            PTPercentage: PTPercentage,
            totalPercentage: totalPercentage,
        })
    }

    const handleNodeChanges = (data) => {
        console.log('Rates & Charges nodes...', data);
        setLinehaulEditRef(data);
        calcBottomTotals();
        // linehaulRef.current = [...data.current]
        //console.log('main linehaul reference...', linehaulRef);
        handleFieldChanges()
    }

    const handleAccChildNodeChanges = (data) => {
        console.log('Rates & Charges nodes Accessorial...', data);
        setAccessorialEditRef(data);
        calcBottomTotals();
        // linehaulRef.current = [...data.current]
        //console.log('main linehaul reference...', linehaulRef);
        handleFieldChanges()
    }

    const getRatesChargesDetails = async () => {
        const controlNumber = params.controlNumber;
        if (controlNumber !== 'new') {
            setInvoiceLoader(true)
            const response = await GET(`/loads/${controlNumber}/details?location=${ordersLocation}`)
            const myArr = [];
            if (response?.charges.length > 0) {
                const rows = [];
                response.charges.map((obj, ind) => {
                    rows.push({
                        Line: obj.Line,
                        Desciption: obj.Desciption,
                        Amount: obj.Amount,
                        Truck: obj.Truck,
                        history: {
                            AccountNumber: obj.AccountNumber,
                            AccountTypeCode: obj.AccountTypeCode,
                            AssociatedStop: obj.AssociatedStop,
                            ChargesTypeCode: obj.ChargesTypeCode,
                            Code: obj.Code,
                            Department: obj.Department,
                            PaperworkCompletedCode: obj.PaperworkCompletedCode,
                            PaperworkRequiredCode: obj.PaperworkRequiredCode,
                            Processed: obj.Processed,
                            RateAmount: obj.RateAmount,
                            Transmitted: obj.Transmitted,
                            Units: obj.Units,
                            Vendor: obj.Vendor
                        },
                    });
                });
                myArr['rows'] = rows;
                PdfGenerator(myArr, billingControlRef.current?.equipmentDetails?.unitNum || 0, billingControlRef.current?.orderBy?.reference || 0)
                setInvoiceLoader(false)
            }
        }
    }

    useEffect(() => {
        if (typeof isEdi210 !== 'undefined') {
            setEdi210State(isEdi210);
        }
    }, [isEdi210])

    return (
        <Paper style={{ padding: '1rem', marginTop: '1em', textAlign: "center" }} variant="outlined">
            <div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className="tablerow" style={{ width: '50%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ textAlign: 'left', alignItems: 'center', justifyContent: 'center', display: 'flex', height: '50px', borderRadius: '10px 0px 0px 0px', backgroundColor: '#0B4387', width: '18%', marginLeft: '0px' }}>
                                            <label style={{ color: 'white' }}>{`Receivables`}</label>
                                        </div>
                                        {!isTemplateScreen ? <><div style={{ justifyContent: 'space-between', display: 'flex', height: '50px', alignItems: 'center', width: '80%' }}>
                                            <div style={{ height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '20px', width: '45%' }}>
                                                <label style={{ marginRight: '6px', width: "50%" }}>Billing Date:</label>
                                                <CustomDatePicker
                                                    size="small"
                                                    variant="outlined"
                                                    value={billingDate || ''}
                                                    onValueChange={(val) => handleBillingDate(val)}
                                                    disabled={isBillingDateLocked}
                                                />
                                            </div>
                                            <div style={{ height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '20px' }}>
                                                <Button
                                                    type='button'
                                                    variant='outlined'
                                                    size='small'
                                                    style={{ marginTop: 5 }}
                                                    color='primary'
                                                    onClick={() => getRatesChargesDetails()}
                                                    startIcon={invoiceLoader ? <Loader /> : ''}
                                                    disabled={(invoiceLoader || params.controlNumber === 'new') ? true : false}
                                                >
                                                    Download Invoice
                                                </Button>
                                            </div>
                                        </div></> : null}
                                    </div>
                                    <div style={{ textAlign: 'left', alignItems: 'center', justifyContent: 'flex-start', display: 'flex', height: '50px', backgroundColor: '#0B4387', paddingLeft: '20px' }}>
                                        <label style={{ color: 'white' }}>Linehaul</label>

                                        <div className="addnewrowdiv">
                                            <Button
                                                variant="text"
                                                className="addbutton"
                                                startIcon={addNewRowBtnLH ? <RemoveCircle /> : <AddCircle />}
                                                onClick={() => setAddNewRowBtnLH(!addNewRowBtnLH)}
                                            >{!addNewRowBtnLH ? 'Add New Row' : 'hide New Row'}</Button>
                                        </div>
                                    </div>
                                </TableCell>

                                <TableCell align="left" colSpan={8} className="tablerow">
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ textAlign: 'left', alignItems: 'center', justifyContent: 'center', display: 'flex', height: '50px', borderRadius: '10px 0px 0px 0px', backgroundColor: '#0B4387', width: '18%', marginLeft: '0px' }}>
                                            <label style={{ color: 'white' }}>{`Payable`}</label>
                                        </div>
                                        {!isTemplateScreen ? (<>   <div style={{ justifyContent: 'space-between', display: 'flex', height: '50px', alignItems: 'center', width: '100%' }}>
                                            <div style={{ height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '20px' }}>
                                                <div style={{
                                                    borderLeft: '4px solid red',
                                                    height: '20px'
                                                }}></div>
                                                <label style={{ marginLeft: '6px' }}> Transmitted {isBillingDateLocked?"Yes":"No"}</label>

                                            </div>
                                            <div style={{ height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '20px' }}>
                                                <Button
                                                    type='button'
                                                    variant='outlined'
                                                    size='small'
                                                    style={{ marginTop: 5 }}
                                                    color='primary'
                                                    // onClick={() => console.log('taking you to new page')}
                                                    href={`http://edray.eaglegroup.com/docviewer/loc/${ordersLocation}/control/${params.controlNumber}`} target='_blank'
                                                >
                                                    View Document
                                                </Button>
                                            </div>
                                        </div>
                                        </>) : null}
                                    </div>

                                    <div style={{ textAlign: 'left', alignItems: 'center', justifyContent: 'flex-end', display: 'flex', height: '50px', backgroundColor: '#0B4387', marginLeft: '0px' }}>
                                        <div className="addnewrowdiv">
                                            <Button
                                                    variant="text"
                                                    className="addbutton"
                                                    // startIcon={addNewRowBtnLH ? <RemoveCircle /> : <AddCircle />}
                                                    onClick={() => { 
                                                        setAddNewRowBtnLH(!addNewRowBtnLH)
                                                        setAddOnlyPayables(!addOnlyPayables)
                                                    }}
                                                    disabled={addOnlyReceivables}
                                                >{!addOnlyPayables  ? 'Add Payable only' : 'hide Payable only'}
                                            </Button>
                                            <Button
                                                    variant="text"
                                                    className="addbutton"
                                                    // startIcon={addNewRowBtnLH ? <RemoveCircle /> : <AddCircle />}
                                                    onClick={() => {
                                                        setAddNewRowBtnLH(!addNewRowBtnLH)
                                                        setAddOnlyReceivables(!addOnlyReceivables)
                                                    }}
                                                    disabled={addOnlyPayables}
                                                >{!addOnlyReceivables ? 'Add Receivable only' : 'hide Receivable only'}
                                            </Button>
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
                <LinehaulRAC
                    orderType={orderType}
                    ordersLocation={ordersLocation}
                    linehaulEditRef={linehaulEditRef}
                    accessorialEditRef={accessorialEditRef}
                    onChildNodeChange={(data) => handleNodeChanges(data)}
                    addNewRowBtnLH={addNewRowBtnLH}
                    hideNewRow={() => { setAddNewRowBtnLH(false); setAddOnlyPayables(false); setAddOnlyReceivables(false) }}
                    FSCPercentage={FSCPercentage}
                    snackBarHandler={snackBarHandler}
                    billingDate={billingDate}
                    tableColumns={tableColumns}
                    unPayableGLCodes={unPayableGLCodes}
                    edi210={edi210State}
                    isTemplateScreen={isTemplateScreen}
                    isReceivableDisabled={addOnlyPayables}
                    isPayableDisabled={addOnlyReceivables}
                />
            </div>
            <div style={{ marginTop: '10px' }}>
                {(edi210State !== 'Y') ? (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" colSpan={7} className="tablerow">

                                            <div style={{ textAlign: 'left', alignItems: 'center', justifyContent: 'flex-start', display: 'flex', height: '50px', backgroundColor: '#0B4387', paddingLeft: '20px' }}>
                                                <label style={{ color: 'white' }}>Accessorial</label>
                                                <div className="addnewrowdiv">
                                                    <Button
                                                        variant="text"
                                                        className="addbutton"
                                                        startIcon={addNewRowBtnAcc ? <RemoveCircle /> : <AddCircle />}
                                                        onClick={() => setAddNewRowBtnAcc(!addNewRowBtnAcc)}
                                                    >{!addNewRowBtnAcc ? 'Add New Row' : 'hide New Row'}</Button>
                                                </div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="left" colSpan={8} className="tablerow">
                                            <div style={{ textAlign: 'left', alignItems: 'center', justifyContent: 'flex-end', display: 'flex', height: '50px', backgroundColor: '#0B4387', marginLeft: '0px' }}>
                                            <div className="addnewrowdiv">
                                                <Button
                                                        variant="text"
                                                        className="addbutton"
                                                        // startIcon={addNewRowBtnLH ? <RemoveCircle /> : <AddCircle />}
                                                        onClick={() => { 
                                                            setAddNewRowBtnAcc(!addNewRowBtnAcc)
                                                            setAddOnlyPayablesAcc(!addOnlyPayablesAcc)
                                                        }}
                                                        disabled={addOnlyReceivablesAcc}
                                                    >{!addOnlyPayablesAcc  ? 'Add Payable only' : 'hide Payable only'}
                                                </Button>
                                                <Button
                                                        variant="text"
                                                        className="addbutton"
                                                        // startIcon={addNewRowBtnLH ? <RemoveCircle /> : <AddCircle />}
                                                        onClick={() => {
                                                            setAddNewRowBtnAcc(!addNewRowBtnAcc)
                                                            setAddOnlyReceivablesAcc(!addOnlyReceivablesAcc)
                                                        }}
                                                        disabled={addOnlyPayablesAcc}
                                                    >{!addOnlyReceivablesAcc ? 'Add Receivable only' : 'hide Receivable only'}
                                                </Button>
                                            </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                        <AccessorialRAC
                            orderType={orderType}
                            ordersLocation={ordersLocation}
                            linehaulEditRef={linehaulEditRef}
                            accessorialEditRef={accessorialEditRef}
                            onChildNodeChange={(data) => handleAccChildNodeChanges(data)}
                            addNewRowBtnAcc={addNewRowBtnAcc}
                            hideNewRow={() => { setAddNewRowBtnAcc(false); setAddOnlyReceivablesAcc(false); setAddOnlyPayablesAcc(false) }}
                            FSCPercentage={FSCPercentage}
                            snackBarHandler={snackBarHandler}
                            billingDate={billingDate}
                            tableColumns={tableColumns}
                            unPayableGLCodes={unPayableGLCodes}
                            isTemplateScreen={isTemplateScreen}
                            isReceivableDisabled={addOnlyPayablesAcc}
                            isPayableDisabled={addOnlyReceivablesAcc}
                        />
                    </>
                ) : null}
            </div>
            <div style={{
                width: '100%', height: '40px', display: 'flex', flexDirection: 'row', marginTop: '10px', justifyContent: 'space-between', alignItems: 'center', borderColor: '#DCDCDC', borderWidth: '4px', padding: '5px', borderStyle: 'solid', marginBottom: '20px'
            }}>
                <label style={{ marginLeft: '20px' }}>Total Revenue: {finalTotals.revenue}</label>
                <label >Total Expense : {finalTotals.expense}</label>
                <label >PT Net:{finalTotals.PTNet}</label>
                <label >Total Net :{finalTotals.totalPTNet}</label>
                <label >PT :{finalTotals.PTPercentage}% </label>
                <label style={{ marginRight: '20px' }}>Total:{finalTotals.totalPercentage}% </label>
            </div>
        </Paper>
    )
}