import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { getUserFromCookie, logoutInternal } from '../../utils/cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleLogout } from 'react-google-login';
import { logout } from '../../store/actions/userActions';
import { useLocation, Link } from 'react-router-dom';
import './NavbarStyle.css';

const MobileviewNavBar = (props) => {

    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const { signOut } = useGoogleLogout({
        clientId: '231767053305-h0ps5uct5r4rdi7b6ft62im3thvv1e1s.apps.googleusercontent.com',
        onLogoutSuccess: () => {
         
            logoutInternal();
            dispatch(logout())
        }
    });
    return (
      <React.Fragment>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
       
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
                <img src={props.profilepic} width={40} height={40} style={{ borderRadius: '50px', marginLeft:'10px', marginBottom: '8px' }} alt="profile" />
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleClose}>
          <Link style={{textDecoration: 'blink', outline: 'none', color: "#0911A7",paddingRight:'100px'}} to={'/tenderList'} >Loads</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
          <Link to={'/ratebook'} style={{textDecoration: 'blink', outline: 'none', color: "#0911A7",paddingRight:'100px'}}>Ratebook</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
          <Link to={'/orders'} style={{textDecoration: 'blink', outline: 'none', color: "#0911A7",paddingRight:'100px'}}>Orders</Link>
          </MenuItem>
          <Divider />
          <MenuItem onClick={signOut}>
            <ListItemIcon>
              <Logout fontSize="small" style={{color: "#0911A7"}} />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
}

export default MobileviewNavBar;