import { CodeRounded } from '@material-ui/icons';
import moment from 'moment';

export const mapShipperFormToClient = (item) => {
  console.log("mapShipperFormToClient",item)
 const data= {
    address: item?.address || "",
    city: item?.city || "",
    code: item?.shipperCode||item?.consignee || "",
    company: item?.company || "",
    email: item?.email || "",
    fax: item?.fax || "",
    firstName: item?.firstName || "",
    id: item?.id || "",
    lastName: item?.lastName || "",
    locationName: item?.locationName || "",
    shipperConsName: item?.name || "",
    internalNote: `${item?.note1} ${item?.note2} ${item?.note3}`,
    // internalNote: item?.note1|| "",
    // internalNotes: `note1: ${item?.note1 || ""}\nnote2: ${item?.note2 || ""}\nnote3: ${item?.note3 || ""}`,
    // internalNote: `rakesh\n,ramesh\n,suresh`,
    // internalNote: `note1: rakesh\nnote2: ramesh\nnote3: suresh`,

note1: item?.note1 || "",
    note2: item?.note2 || "",
    note3: item?.note3 || "",
    phone: item?.phone || "",
    state: item?.state || "",
    yard: item?.yard || "N",
    zip: item?.zip || ""
  }
  return data
  
}
//new
export const mapShipperFormDataToClient = (item) => {
  console.log("mapShipperFormToClient",item)
 const shipperForm= mapShipperFormToClient(item)
  const shipperContacts=maptoClientContactTable(item?.contacts,item|| "")
  const shipperHour=maptoClientHoursTable(item?.hours)
  return { shipperForm,shipperContacts,shipperHour}
  
}

// export const mapShipperFormDataToServer = (item) => {
//   console.log("mapShipperFormToClient",item)
//   mapShipperFormToServer(item.shipperForm)
//  contacts:mapShipperContactToServer(item.shipperContacts)
//   hours:mapHoursTableToServer(item?shipperHour)
//   return { shipperForm,shipperContacts,shipperHour}
  
// }

function splitStringIntoThree(text) {
  const maxLength = 125;
  const chunkSize = 25;
  const chunks = [];

  if (text.length <= chunkSize) {
      chunks.push(text);
      chunks.push('');
      chunks.push('');
  } else if (text.length <= chunkSize * 2) {
      chunks.push(text.substring(0, chunkSize));
      chunks.push(text.substring(chunkSize));
      chunks.push('');
  } else {
      chunks.push(text.substring(0, chunkSize));
      chunks.push(text.substring(chunkSize, chunkSize * 2));
      chunks.push(text.substring(chunkSize * 2));
  }

  return chunks;
}

export const mapShipperFormToServer = (item) => {
  console.log("mapShipperFormToServer",item)
  const splitNote =item?.internalNote?.match(/.{1,25}/g)
  console.log("splitNote",splitNote)
 const data= {
    address: item?.address || "",
    city: item?.city || "",
    code: item?.code || "",
    // company: item?.company || 1,
    // email: item?.email || "",
    fax: item?.fax || "",
    // firstName: item?.firstName || "",
    // id: item?.id || "",
    // lastName: item?.lastName || "",
    // locationName: item?.locationName || "",
    name: item?.shipperConsName || "",
    note1: splitNote && splitNote[0] ? splitNote[0] : "",
    note2: splitNote && splitNote[1] ? splitNote[1] : "",
    note3:splitNote && splitNote[2] ? splitNote[2] : "",
    phone: item?.phone || "",
    state: item?.state || "",
    yard: item?.yard || "N",
    zip: item?.zip || ""
  }
  return data
  
}
export const convert_hours_data = (receiving_hours, shipping_hours, code) => {
  console.log("receiving_hours,shipping_hours,code", receiving_hours, shipping_hours, code);
  
  const data = {
      hours: [
          {
              seq: receiving_hours && receiving_hours.seq ? receiving_hours.seq : -1,
              hoursType: "R",
              shipperCode: code,
              day1: receiving_hours && receiving_hours.day1 ? receiving_hours.day1 : "",
              day2: receiving_hours && receiving_hours.day2 ? receiving_hours.day2 : "",
              day3: receiving_hours && receiving_hours.day3 ? receiving_hours.day3 : "",
              day4: receiving_hours && receiving_hours.day4 ? receiving_hours.day4 : "",
              day5: receiving_hours && receiving_hours.day5 ? receiving_hours.day5 : "",
              day6: receiving_hours && receiving_hours.day6 ? receiving_hours.day6 : "",
              day7: receiving_hours && receiving_hours.day7 ? receiving_hours.day7 : "",
          },
          {
              seq: shipping_hours && shipping_hours.seq ? shipping_hours.seq : -1,
              hoursType: "S",
              shipperCode: code,
              day1: shipping_hours && shipping_hours.day1 ? shipping_hours.day1 : "",
              day2: shipping_hours && shipping_hours.day2 ? shipping_hours.day2 : "",
              day3: shipping_hours && shipping_hours.day3 ? shipping_hours.day3 : "",
              day4: shipping_hours && shipping_hours.day4 ? shipping_hours.day4 : "",
              day5: shipping_hours && shipping_hours.day5 ? shipping_hours.day5 : "",
              day6: shipping_hours && shipping_hours.day6 ? shipping_hours.day6 : "",
              day7: shipping_hours && shipping_hours.day7 ? shipping_hours.day7 : "",
          }
      ]
  };

  return data;
};



//above
export const maptoShipperTable = (shipperRes) => {
 
  try {
    let shipper = shipperRes?.map((item,index) => {
      return {id: index,
      address:item?._source.address,
      city:item?._source.city,
      code:item?._source.shipperCode,
      company:item?._source.company,
      email:item?._source.email,
      fax:item?._source.fax,
      firstName:item?._source.firstName,
      lastName:item?._source.lastName,
      locationName:item?.locationName,
      name:item?._source.name,
      note1:item?._source.note1,
      note2:item?._source.note2,
      note3:item?._source.note3,
      phone:item?._source.phone,
      state:item?._source.state,
      yard:item?._source.yard,
      zip:item?._source.zip
      }
    })
    return shipper;

  } catch (e) {
    console.log('Error occurred while mapping', e)
    return false;
  }
}

export const maptoClientContactTable = (shipperRes,shipperData) => {


  try {
    // if (!shipperRes) {
    //   return  getDefaultShipperContact()
      
    // }
    let shipperContacts = shipperRes?.map((item,index) => {
      return {id: index,
        IMAddr: item?.IMAddr|| "",
        addr1: item?.addr1|| "",
        addr2: item?.addr2|| "",
        addr3: item?.addr3|| "",
        agentCode: item?.agentCode|| "",
        city: item?.city|| "",
        company: item?.company|| "",
        country: item?.country|| "",
        customer: item?.customer|| "",
        email: item?.email|| "",
        fax: item?.fax|| "",
        contact_name: `${item?.fname} ${item?.lname}`|| "",
        locationNumber: item?.locationNumber|| "",
        mobilePhone: item?.mobilePhone|| "",
        note1: item?.note1|| "",
        note2: item?.note2|| "",
        note3: item?.note3|| "",
        phone_number: item?.primaryPhone|| "",
        secondaryPhone: item?.secondaryPhone|| "",
        seq: item?.seq|| "",
        shipperCode: item?.shipperCode||shipperData?.shipperCode|| "",
        state: item?.state|| "",
        title: item?.title|| "",
        zip: item?.zip|| "",
        primary:item?.isPrimary ||"N"
        
        
      }
    })
    const additionalContact={
      id: -1,
        IMAddr:  "",
        addr1: "",
        addr2:  "",
        addr3:  "",
        agentCode: "",
        city:  "",
        company: "",
        country:  "",
        customer:"",
        email: shipperData?.email|| "",
        fax: "",
        contact_name: `${shipperData?.firstName?shipperData?.firstName:""} ${shipperData?.lastName?shipperData?.lastName:""}`|| "",
        locationNumber:"",
        mobilePhone:  "",
        note1:"",
        note2:"",
        note3: "",
        phone_number: shipperData?.phone|| "",
        secondaryPhone: "",
        seq:"",
        shipperCode: shipperData?.shipperCode|| "",
        state:"",
        title: "",
        zip:"",
        primary:"N",
        isDisabled:true
        
        
      
    }
    if(shipperData?.phone){
      shipperContacts.unshift(additionalContact)
    }
    
    return shipperContacts;

  } catch (e) {
    console.log('Error occurred while mapping', e)
    return false;
  }
}

// export const maptoClientHoursTable = (shipperRes) => {

//   try {
//     let shipperHours = shipperRes?.map((item,index) => {
//       return {id: index,
       
//         hoursType: item?.hoursTypes|| "",
//         shipperCode: item?.shipperCode|| "",
//         day1: item?.day1|| "",
//         day2: item?.day2|| "",
//         day3: item?.day3|| "",
//         day4: item?.day4|| "",
//         day5: item?.day5|| "",
//         day6: item?.day6|| "",
//         day7: item?.day7|| "",
        
//       }
//     })
//     return shipperHours;

//   } catch (e) {
//     console.log('Error occurred while mapping', e)
//     return false;
//   }
// }

const getDefaultHoursObject = (hoursType) => ({
  // id: 0,
  seq:-1,
  hoursType,
  shipperCode: "",
  day1: "",
  day2: "",
  day3: "",
  day4: "",
  day5: "",
  day6: "",
  day7: "",
});

export const maptoClientHoursTable = (shipperRes) => {
  console.log("shipperRes",shipperRes)
  try {
    let receivingHours = [];
    let shippingHours = [];

    // Check if shipperRes is null or undefined
    if (!shipperRes) {
      receivingHours.push(getDefaultHoursObject("R"));
      shippingHours.push(getDefaultHoursObject("S"));
      return { receivingHours, shippingHours };
    }

    shipperRes.forEach((item, index) => {
      const hoursData = {
        // id: index,
        seq:item?.seq?item?.seq:-1,
        hoursType: item?.hoursTypes || "",
        shipperCode: item?.shipperCode || "",
        day1: item?.day1 || "",
        day2: item?.day2 || "",
        day3: item?.day3 || "",
        day4: item?.day4 || "",
        day5: item?.day5 || "",
        day6: item?.day6 || "",
        day7: item?.day7 || "",
      };

      if (item?.hoursTypes === "R" && receivingHours.length === 0) {
        receivingHours.push(hoursData);
      } else if (item?.hoursTypes === "S" && shippingHours.length === 0) {
        shippingHours.push(hoursData);
      }
    });

    // If receivingHours or shippingHours is empty, push default hours object
    if (receivingHours.length === 0) {
      receivingHours.push(getDefaultHoursObject("R"));
    }

    if (shippingHours.length === 0) {
      shippingHours.push(getDefaultHoursObject("S"));
    }

    return { receivingHours: receivingHours[0], shippingHours: shippingHours[0] };
  } catch (e) {
    console.log('Error occurred while mapping', e);
    return false;
  }
};


export const getDefaultShipperContact = () => {

  return {
      id: "",
      IMAddr: "",
      addr1: "",
      addr2: "",
      addr3: "",
      agentCode: "",
      city: "",
      company: "",
      country: "",
      customer: "",
      email: "",
      fax: "",
      contact_name: "",
      locationNumber: "",
      mobilePhone: "",
      note1: "",
      note2: "",
      note3: "",
      phone_number: "",
      secondaryPhone: "",
      seq: "",
      shipperCode: "",
      state: "",
      title: "",
      zip: "",
      primary:"N",
      isNew: true

  }
}

// export const mapShipperContactToServer = (item) => {

//   return {
//     IMAddr: item?.IMAddr|| "",
//     addr1: item?.addr1|| "",
//     addr2: item?.addr2|| "",
//     addr3: item?.addr3|| "",
//     agentCode: item?.agentCode|| "",
//     city: item?.city|| "",
//     company: item?.company|| "",
//     country: item?.country|| "",
//     customer: item?.customer|| "",
//     email: item?.email|| "",
//     fax: item?.fax|| "",
//     fname: item.contact_name ? item.contact_name.split(" ")[0] : "",
//     lname: item.contact_name ? item.contact_name.split(" ")[1] : "",
//     locationNumber: item?.locationNumber|| "",
//     mobilePhone: item?.mobilePhone|| "",
//     note1: item?.note1|| "",
//     note2: item?.note2|| "",
//     note3: item?.note3|| "",
//     primaryPhone: item?.phone_number|| "",
//     secondaryPhone: item?.secondaryPhone|| "",
//     seq: item?.seq|| "",
//     shipperCode: item?.shipperCode|| "",
//     state: item?.state|| "",
//     title: item?.title|| "",
//     zip: item?.zip|| "",
//     isPrimary:item?.primary ||"N"

//   }
// }


export const mapShipperContactToServer = (data) => {
  return data
      .filter(item => item.isNew === false ||item.hasChanged===true)
      .map(item => ({

          IMAddr: item?.IMAddr || "",
          addr1: item?.addr1 || "",
          addr2: item?.addr2 || "",
          addr3: item?.addr3 || "",
          agentCode: item?.agentCode || "",
          city: item?.city || "",
          // company: item?.company || "",
          country: item?.country || "",
          customer: item?.customer || "",
          email: item?.email || "",
          fax: item?.fax || "",
          fname: item.contact_name ? item.contact_name.split(" ")[0] : "",
          lname: item.contact_name ? item.contact_name.split(" ")[1] ||"" : "",
          // locationNumber: item?.locationNumber || "",
          mobilePhone: item?.mobilePhone || "",
          note1: item?.note1 || "",
          note2: item?.note2 || "",
          note3: item?.note3 || "",
          primaryPhone: item?.phone_number || "",
          secondaryPhone: item?.secondaryPhone || "",
          seq: item?.seq?item?.seq:-1,
          // shipperCode: item?.shipperCode || "",
          state: item?.state || "",
          title: item?.title || "",
          zip: item?.zip || "",
          isPrimary: item?.primary || "N"
      }));
};
