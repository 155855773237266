import { useState, useEffect, useCallback } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";

// import customize components
import LinehaulTableRow from './components/LinehaulTableRow';
import SubItemTableRow from './components/SubItemTableRow';
import SplitItemsTableRow from './components/SplitItemsTableRow';
import TableNewRowForm from './components/TableNewRowForm';

//import action functions
import { deleteOrderRatesChargesData } from "../../../store/actions/orderActions";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

export default function LinehaulRAC(props) {
    const { linehaulEditRef, accessorialEditRef, onChildNodeChange,
        orderType, ordersLocation, addNewRowBtnLH, hideNewRow, FSCPercentage, snackBarHandler,
        billingDate, tableColumns, unPayableGLCodes, edi210,isTemplateScreen, isReceivableDisabled, isPayableDisabled
    } = props;
    const [rows, setRows] = useState(linehaulEditRef.current || []);
    const [isSplitToggle, setIsSplitToggle] = useState({});
    const [billingDateCounter, setBillingDateCounter] = useState(0);
    const dispatch = useDispatch();
    const params = useParams();

    const handleSplitToggle = (itemIndx, state) => {
        setIsSplitToggle({
            ...isSplitToggle,
            [itemIndx]: !state
        });
    }

    const handleLineItemDelete = useCallback(async (lineNumber) => {
        let delReqParam = {
            location: params.locationNumber,
            control: params.controlNumber,
            lineNo: lineNumber
        }
        snackBarHandler({
            status: true,
            type: 'info',
            message: 'Updating Rates & Charges...'
        });
        const apiRes = dispatch(deleteOrderRatesChargesData(delReqParam)).then((res) => {
            if (res?.status === 'success') {
                snackBarHandler({
                    status: true,
                    type: 'success',
                    message: 'Rates & Charges records has been updated!'
                });
                return true;
            }
            snackBarHandler({
                status: true,
                type: 'error',
                message: res.message
            });
            return false;
        })
        return apiRes;
    });

    const handleNewRow = (data) => {
        linehaulEditRef.current.push(data)
        setRows(linehaulEditRef.current);
        onChildNodeChange(linehaulEditRef)
    }

    const handleExistingRow = (data, indx) => {
        linehaulEditRef.current[indx] = { ...data };
        setRows(linehaulEditRef.current);
        onChildNodeChange(linehaulEditRef)
    }

    // TODO : On Billing Date change enable the receivable & payable
    // At this moment its not functioning due to some re-render issue
    useEffect(() => {
        if (billingDate === '' && billingDateCounter === 1) {
            //let modifiedReceivables = [];
            const modifiedReceivables = rows.map((obj, indx) => {
                let modifiedReceivablesObj = {
                    ...obj,
                    receivable: {
                        ...obj.receivable,
                        isFreeLockReceivable: false,
                        subItems: obj.receivable?.subItems?.length > 0 ? [{
                            ...obj.receivable?.subItems?.[0],
                            isFreeLockReceivable: false
                        }] : []
                    }
                }
                //modifiedReceivables.push(modifiedReceivablesObj)
                return modifiedReceivablesObj;
            });
            linehaulEditRef.current = [...modifiedReceivables];
            setRows(linehaulEditRef.current);
            onChildNodeChange(linehaulEditRef);
        }
        setBillingDateCounter(1);
    }, [billingDate])

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className='ratesChargeTbl'>
                <TableHead sx={{
                    backgroundColor: 'rgb(221, 228, 254)'
                }}>
                    <TableRow>
                        {tableColumns.map((column) => (
                            <TableCell align="center">{column.label}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, indx) => (
                        <>
                            <LinehaulTableRow
                                orderType={orderType}
                                rowIndex={indx}
                                key={indx}
                                row={row}
                                ordersLocation={ordersLocation}
                                linehaulEditRef={linehaulEditRef}
                                FSCPercentage={FSCPercentage}
                                accessorialEditRef={accessorialEditRef}
                                tableType={'L'}
                                onChildNodeChange={onChildNodeChange}
                                isSplitToggle={isSplitToggle[indx]}
                                handleSplitToggle={(indx, state) => handleSplitToggle(indx, state)}
                                handleLineItemDelete={(lineNumber) => handleLineItemDelete(lineNumber)}
                                handleExistingRow={(data, indx) => handleExistingRow(data, indx)}
                                snackBarHandler={(data) => snackBarHandler(data)}
                                unPayableGLCodes={unPayableGLCodes}
                                edi210={edi210}
                            />
                            {(row?.payable?.splitItems?.length > 0) ? (
                                row.payable.splitItems.map((splitItem, splitItemIndx) => (
                                    <SplitItemsTableRow
                                        orderType={orderType}
                                        row={splitItem}
                                        linehaulEditRef={linehaulEditRef}
                                        rowIndex={indx}
                                        onChildNodeChange={onChildNodeChange}
                                        splitType='parent'
                                        splitItemIndx={splitItemIndx}
                                        handleExistingRow={(data, indx) => handleExistingRow(data, indx)}
                                    />
                                ))
                            ) : null}
                            {(row?.payable?.subItems?.length > 0 || row?.receivable?.subItems?.length) ? (
                                <>
                                    <SubItemTableRow
                                        row={row}
                                        rowIndex={indx}
                                        handleLineItemDelete={(lineNumber) => handleLineItemDelete(lineNumber)}
                                        handleExistingRow={(data, indx) => handleExistingRow(data, indx)}
                                    />

                                    {(row?.payable?.subItems?.[0]?.splitItems?.length > 0) ? (
                                        row.payable.subItems?.[0].splitItems.map((splitItem, splitSubItemIndx) => (
                                            <SplitItemsTableRow
                                                orderType={orderType}
                                                row={splitItem}
                                                linehaulEditRef={linehaulEditRef}
                                                rowIndex={indx}
                                                onChildNodeChange={onChildNodeChange}
                                                splitType='child'
                                                splitItemIndx={splitSubItemIndx}
                                                handleExistingRow={(data, indx) => handleExistingRow(data, indx)}
                                            />
                                        ))
                                    ) : null}
                                </>
                            ) : null}
                        </>
                    ))}
                    {addNewRowBtnLH ? (
                        <TableNewRowForm
                            onChildNodeChange={onChildNodeChange}
                            orderType={orderType}
                            tableType='L'
                            linehaulEditRef={linehaulEditRef}
                            addNewRowBtnLH={addNewRowBtnLH}
                            hideNewRow={hideNewRow}
                            accessorialEditRef={accessorialEditRef}
                            handleNewRow={(data) => handleNewRow(data)}
                            isReceivableDisabled={billingDate !== '' || isReceivableDisabled ? true : false}
                            isPayableDisabled={isPayableDisabled ? true : false}
                            unPayableGLCodes={unPayableGLCodes}
                            ordersLocation={ordersLocation}
                            edi210={edi210}
                            isTemplateScreen={isTemplateScreen}
                        />
                    ) : null}
                </TableBody>
            </Table>
        </TableContainer>
    );
}