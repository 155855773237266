
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Paper, TableContainer, Box, TextField,Button } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

export default function TemplateTable(props) {
  const {
    rows,
    setSelectedRow,
    setSelectedRowData,
    rowData,
    isLoading,
    handleQueryPageChange,
    rowsCount,
    handleSortChangeData,
    selectedRow,
    selectedRowNote,
    oldEdiOrderData,
    setOldEdiOrderData,
    nonMatchedJsonData,
    currentEdiJson,
  } = props;

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 1,
  });
  const [sortData, setSortData] = useState({
    field: "customer",
    sort: "asc",
  });
  const [searchTextJson, setSearchTextJson] = useState("");
  const [highlightedFields, setHighlightedFields] = useState([]);
  const [jsonActiveButton,setJsonActiveButton]=useState("CHANGE")
  useEffect(() => {
    setSearchTextJson("")
    handleSearchText("")
    setJsonActiveButton("CHANGE")

  },[selectedRowNote] );

  const tableColumns = [
    {
      field: "customer",
      headerName: "Customer",
      headerClassName: "MuiDataGrid-columnHeaders",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "customerRef",
      headerName: "Cust Ref",
      headerClassName: "MuiDataGrid-columnHeaders",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "changingType",
      headerName: "Change Type",
      headerClassName: "MuiDataGrid-columnHeaders",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "receivedDateTime",
      headerName: "Received Date/Time",
      headerClassName: "MuiDataGrid-columnHeaders",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "expiresDateTime",
      headerName: "Expires Date/Time",
      headerClassName: "MuiDataGrid-columnHeaders",
      flex: 1,
      minWidth: 130,
    },
    props.activeButton === "History" &&{
      field: "processedDateTime",
      headerName: "Processed Date/Time",
      headerClassName: "MuiDataGrid-columnHeaders",
      flex: 1,
      minWidth: 130,
    },
    props.activeButton === "History" &&{
      field: "processedType",
      headerName: "Processed Type",
      headerClassName: "MuiDataGrid-columnHeaders",
      flex: 1,
      minWidth: 130,
    }
    
  ];


  const handleRowClick = (params) => {
    setSelectedRow(params?.row?.id);
    setSelectedRowData(params.row);
  };

  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page });
    handleQueryPageChange(params, sortData);
  };

  const handleSortChange = (sortModel) => {
    const sortDataToServer = {
      field: "customer",
      sort: "asc",
    };
    if (sortModel[0]) {
      const { field, sort } = sortModel[0];
      sortDataToServer.field = field;
      sortDataToServer.sort = sort;
    }
    setSortData(sortDataToServer);
    handleSortChangeData(paginationModel, sortDataToServer);
  };

  const isFieldNonMatched = (path) => {
    return nonMatchedJsonData.some(item => item.field === path);
};
  const renderData = (data, path = '', depth = 0) => {
    const getColor = (depth) => {
        const maxDepth = 5; // Adjust this value if necessary
        const ratio = Math.min(depth / maxDepth, 1); // Normalize depth to [0, 1]

        // Black (0,0,0) to light grey (211,211,211)
        const colorValue = Math.floor(0 + ratio * 211); // Value ranges from 0 to 211

        return `rgb(${colorValue}, ${colorValue}, ${colorValue})`;
    };

    if (typeof data !== 'object' || data === null) {
      const isNonMatched = isFieldNonMatched(path);
      return <span style={{ color: isNonMatched ? 'red' : "rgb(11, 67, 135)", marginLeft: 10 }}>{String(data)}</span>;
        // return <span style={{ color: "rgb(11, 67, 135)", marginLeft: 10 }}>{String(data)}</span>;
    }

    if (Array.isArray(data)) {
        return (
            <ul>
                {data.map((item, index) => (
                    <li key={index}>
                        {renderData(item, `${path}[${index}]`, depth)}
                    </li>
                ))}
            </ul>
        );
    }

    return (
        <div style={{ marginLeft: `${depth * 20}px` }}>
            {Object.keys(data).map((key) => {
                const currentPath = path ? `${path}.${key}` : key;
                const isHighlighted = highlightedFields.includes(currentPath);
                return (
                    <div key={key} style={{ backgroundColor: isHighlighted ? '#ffffcc' : 'transparent', color: getColor(depth) }}>
                        <strong>{key}:</strong> {renderData(data[key], currentPath, depth + 1)}
                    </div>
                );
            })}
        </div>
    );
};

  const handleSearchText = (event) => {
    const searchTextJson = event?.trim().toLowerCase();
    setSearchTextJson(searchTextJson);
    if(searchTextJson===""){
      handleSearch("")
    }
  }
  const handleSearch = (event) => {
    const searchTextJson = event?.toLowerCase();
    if (!searchTextJson) {
      setHighlightedFields([]);
      return;
    }
  
    const fieldsToHighlight = [];
  
    // Recursive function to search through nested objects/arrays
    const searchNested = (obj, path = '') => {
      Object.keys(obj).forEach(key => {
        const value = obj[key];
        const formatKey=(typeof key === 'string' && !isNaN(Number(key)))?`[${Number(key)}]`:key
        const newPath =path ?(typeof key === 'string' && !isNaN(Number(key)))?`${path}${formatKey}`:`${path}.${formatKey}`:`${formatKey}`
        // const newPath = path ? `${path}.${formatKey}` : key;
        if (typeof value === 'object' && value !== null) {
          if (key.toLowerCase().includes(searchTextJson) || String(value).toLowerCase().includes(searchTextJson)) {
            fieldsToHighlight.push(newPath);
          }
          searchNested(value, newPath);
        } else {
          if (key.toLowerCase().includes(searchTextJson) || String(value).toLowerCase().includes(searchTextJson)) {
            fieldsToHighlight.push(newPath);
          }
        }
      });
    };
  
    // searchNested(selectedRowNote);
    jsonActiveButton ==="ORIGINAL"?searchNested(selectedRowNote):searchNested(currentEdiJson);
    setHighlightedFields(fieldsToHighlight);
  };
  // const { BillingRamp, ControlNumber, LocationNumber, OrderType, ...newSelectedRowNote } = selectedRowNote;
  // const { BillingRamp, ControlNumber, LocationNumber, OrderType, ...newcurrentEdiJson } = currentEdiJson;
  // Destructure selectedRowNote
const { BillingRamp: br1, ControlNumber: cn1, LocationNumber: ln1, OrderType: ot1, ...newSelectedRowNote } = selectedRowNote  || {}; 
const { BillingRamp: br2, ControlNumber: cn2, LocationNumber: ln2, OrderType: ot2, ...newCurrentEdiJson } = currentEdiJson  || {}; 



  return (
    <>
     
     <TableContainer component={Paper} style={{ width: "100%", height:selectedRowNote?"85%":"100%" }}>
      <Box
        sx={{
          width: "100%",
          overflowX: "auto",
          height: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
          "& .tableTH": {
            fontWeight: 700,
          },
          "& .MuiDataGrid-columnHeaders": {
            // backgroundColor: "rgba(9, 29, 163, 1)",
            backgroundColor: "rgb(11, 67, 135)",
            color: "rgba(255, 255, 255, 1)",
            fontSize: "12px",
          },
          "& .MuiDataGrid-sortIcon": {
            opacity: 1,
            color: "rgba(255, 255, 255, 1)",
          },
          "& .MuiDataGrid-menuIconButton": {
            opacity: 1,
            color: "rgba(255, 255, 255, 1)",
          },
          "& .super-app-theme--cell": {
            backgroundColor: "white",
            fontSize: "11px",
          },
          "& .selected-row": {
              backgroundColor: "#D3D3D3 !important", // Highlight selected row
            },
        }}
      >
        <DataGridPro
          rows={rows}
          rowCount={rowsCount}
          columns={tableColumns}
          getRowClassName={(params) =>
            selectedRow === params?.row?.id ? "selected-row" : ""
          }
          editMode="row"
          columnHeaderHeight={30}
          rowHeight={30}
          checkboxSelection={false}
          disableRowSelectionOnClick
          {...rows}
          onRowClick={handleRowClick}
          loading={isLoading}
          paginationMode="server"
          pagination={true}
          paginationModel={paginationModel}
          pageSizeOptions={[10, 25, 50]}
          onPaginationModelChange={(params) => {
            handlePageChange(params);
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: paginationModel.pageSize,
                page: paginationModel.page,
              },
            },
          }}
          sortingOrder={["desc", "asc"]}
          sortingMode="server"
          onSortModelChange={handleSortChange}
        />
      </Box>
    
    </TableContainer>
      {selectedRowNote && (
        <>
       
        <Box
          sx={{
            padding: 2,
            marginTop: 2,
            backgroundColor: '#f5f5f5',
            borderRadius: 1,
            maxHeight: '500px',
            overflowY: 'auto'
          }}
        >
           <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
           <TextField
        label="Search JSON fields"
        variant="outlined"
        value={searchTextJson}
        onChange={(event)=>{handleSearchText(event.target.value)}}
        onKeyPress={(event) => { event.key === 'Enter' && handleSearch(event.target.value) }}
        fullWidth
        margin="normal"
        sx={{
          marginBottom: 4  
        }}
      />
      {/* {
      (currentEdiJson.TypeDesc!=="ORIGINAL")?(<Box sx={{ display: "flex", gap: 1,  margin:2}}>
        <Button
          variant={jsonActiveButton === 'CHANGE' ? 'contained' : 'outlined'}
          onClick={() =>{handleSearchText("") ; setJsonActiveButton('CHANGE')}}
        >
          
          {currentEdiJson.TypeDesc}
          
        </Button>
        <Button
          variant={jsonActiveButton  === 'ORIGINAL' ? 'contained' : 'outlined'}
          onClick={() => {handleSearchText("") ;setJsonActiveButton('ORIGINAL')}}
        >
          ORIGINAL
        </Button>
             
            
            </Box>):null
            } */}
            </Box>
            {jsonActiveButton === 'ORIGINAL'?renderData(newSelectedRowNote):renderData(newCurrentEdiJson)}
          {/* {renderData(newSelectedRowNote)} */}
        </Box>
        </>
      )}
    </>
  );
}
