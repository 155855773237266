import {
    Box,
    Paper,
    TableContainer
} from "@mui/material";
import { DataGridPro } from '@mui/x-data-grid-pro';

import { useEffect, useState, useCallback } from "react";

import './DispatchStyle.css'
import SubItemTable from "./components/SubItemTable";

// Icons 
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import TruckDriverDetailEditPanel from "../TruckDriver/component/TruckDriverDetailEditPanel"
import { styled } from '@mui/material/styles';

const TruckCell = styled('span')({
    color: '#0000EE',
    // textDecoration:
    cursor: 'pointer', 
  });
const tableColumns = [
    { field: 'truck', headerName: 'Truck', headerClassName: 'MuiDataGrid-columnHeaders', sortable: false, flex: 1,  renderCell: (params) => (
        <TruckCell>{params.value}</TruckCell>
      ),},
    { field: 'driverName', headerName: 'Driver', headerClassName: 'MuiDataGrid-columnHeaders', sortable: false, flex: 1 },
    { field: 'loadsCount', headerName: 'Load Count', headerClassName: 'MuiDataGrid-columnHeaders', sortable: false, flex: 1, align: 'center' },
    { field: 'rest', headerName: 'Rest', headerClassName: 'MuiDataGrid-columnHeaders', sortable: false, flex: 1 },
    { field: 'driving', headerName: 'Driving', headerClassName: 'MuiDataGrid-columnHeaders', sortable: false, flex: 1 },
    { field: 'workday', headerName: 'Workday', headerClassName: 'MuiDataGrid-columnHeaders', sortable: false, flex: 1 },
    { field: 'cycle', headerName: 'Cycle', headerClassName: 'MuiDataGrid-columnHeaders', sortable: false, flex: 1 },
    { field: 'dutyStatus', headerName: 'Duty Status', headerClassName: 'MuiDataGrid-columnHeaders', sortable: false, flex: 1 },
    {
        field: 'isAvailable', headerName: 'Available', headerClassName: 'MuiDataGrid-columnHeaders',
        sortable: false, flex: 1, hide: true,
        type: 'singleSelect',
        valueOptions: ["Y", "N"]
    },
    { field: 'sortField', headerName: 'Sort', headerClassName: 'MuiDataGrid-columnHeaders', sortable: true, flex: 1 },
    // { field: 'isUsable', headerName: 'Usable', headerClassName: 'MuiDataGrid-columnHeaders', sortable: false, flex: 1, hide: true }
]

export default function DriverTable({ trucksData, stopsDataParent, isLoading, isTrucksLoading, isAvailableState, isRowOrderEnabled }) {
    //const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowIds, setRowIds] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [openDetailEditPanel, setOpenDetailEditPanel] = useState(false)
    const [truckDriverNo,setTruckDriverNo]=useState(null)

    useEffect(async () => {
        await groupStopsData(trucksData, stopsDataParent);
    }, [trucksData, stopsDataParent])

    useEffect(() => {
        if (isAvailableState !== 'all') {
            let filteredTrucks = trucksData.length > 0 && trucksData.filter((e) => e.isAvailable === isAvailableState);
            groupStopsData(filteredTrucks, stopsDataParent)
        } else {
            groupStopsData(trucksData, stopsDataParent);
        }
    }, [isAvailableState])

    const groupStopsData = useCallback(async (trucks, stops) => {
        if (trucks?.length > 0) {
            let filteredRowsIds = [];
            const resultSet = trucks.map(e => {
                const rowCount = stops.filter(e1 => {
                    if ( // drop loads condition
                        e1.spotDate === 0 &&
                        e1.arriveTruck !== 0 &&
                        e1.arriveDriver !== 0 &&
                        e1.departTruck === 0 &&
                        e1.departDriver === 0 &&
                        e1.arriveDriver === e.driverId && e1.arriveTruck === e.truck
                    ) {
                        return e1;
                    } else if ( // pull loads condition
                        e1.spotDate !== 0 &&
                        e1.arriveTruck !== 0 &&
                        e1.arriveDriver !== 0 &&
                        e1.departTruck !== 0 &&
                        e1.departDriver !== 0 &&
                        e1.arriveTruckStatus === 'C' &&
                        (e1.departDriver === e.driverId && e1.departTruck === e.truck)
                    ) {
                        return e1;
                    } else if ( // pull loads condition -2
                        e1.spotDate !== 0 &&
                        e1.arriveTruck !== 0 &&
                        e1.arriveDriver !== 0 &&
                        e1.departTruck !== 0 &&
                        e1.departDriver !== 0 &&
                        (e1.departDriver === e.driverId && e1.departTruck === e.truck)
                    ) {
                        return e1;
                    } else if ( // live unloads condition
                        e1.arriveTruck !== 0 &&
                        e1.arriveDriver !== 0 &&
                        e1.departTruck !== 0 &&
                        e1.departDriver !== 0 &&
                        (e1.departDriver === e.driverId && e1.departTruck === e.truck)
                    ) {
                        return e1;
                    }
                    return;
                }).length;

                if (rowCount > 0 && e.isAvailable === 'Y') {
                    filteredRowsIds.push(e.driverId + '-' + e.truck);
                }
                
                return { ...e, loadsCount: rowCount }
            })
            const sortedTrucksData = resultSet.sort((a, b) => a.sortField - b.sortField);
            setRows(sortedTrucksData);
            setRowIds(filteredRowsIds)
        } else {
            setRows([]);
        }
    }, [trucksData, stopsDataParent])

    const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
        setRowIds(newIds);
    }, []);

    const handleExpandAll = (state) => {
        if (state) {
            const driverIdsArr = rows.map(e => e.driverId + '-' + e.truck);
            setRowIds(driverIdsArr);
        } else {
            setRowIds([]);
        }
        setIsExpanded(state);
    }

    const handleCloseModal = () => {
        console.log("close")
        setOpenDetailEditPanel(null);
       
      };
      function handleTruckClick(rowId) {
        console.log(`Truck cell clicked, row ID: ${rowId}`);
        // You can replace the above line with your custom function call
        setOpenDetailEditPanel(true)
        // setRowIds(rowId)
        setTruckDriverNo(rowId)
      }
        const handleCellClick = (params) => {
          if (params.field === 'truck') {
            handleTruckClick(params.id);
          }
        };
      
    return (
        <Box
            style={{ height: 'auto', width: '100%', flexDirection: 'column', display: 'flex', padding: '10px' }}
            sx={{
                '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: "rgba(11, 67, 135, 1)",
                    color: "rgba(255, 255, 255, 255)",
                    fontSize: 12,
                },
                '& .MuiDataGrid-menuIconButton': {
                    color: "rgba(255, 255, 255, 255)",
                    fontSize: 12,
                }
            }}
        >
            <IconButton
                aria-label="Collapse All"
                onClick={() => handleExpandAll(!isExpanded)}
                sx={{
                    position: 'absolute',
                    zIndex: 99,
                    color: 'white',
                    marginLeft: '5px'
                }}
            >
                {isExpanded ? <RemoveIcon /> : <AddIcon />}
            </IconButton>

            <DataGridPro
                rows={rows}
                getRowId={(row) => row.driverId + '-' + row.truck}
                columns={tableColumns}
                columnHeaderHeight={40}
                rowHeight={30}
                getDetailPanelContent={({ row }) => <SubItemTable row={row} stopsData={stopsDataParent} isLoading={isLoading} isRowOrderEnabled={isRowOrderEnabled} />}
                getDetailPanelHeight={({ row }) => 'auto'}
                hideFooter={true}
                hideFooterRowCount={true}
                disableColumnMenu={false}
                //onRowClick={handleRowClick}
                onCellClick={handleCellClick}
                loading={isTrucksLoading}
                detailPanelExpandedRowIds={rowIds}
                onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                getRowClassName={(params) => {
                    if (params.row?.isAvailable !== 'Y') {
                        return 'disableDriverRow';
                    } else {
                        return 'defaultLighBlue';
                    }
                }}
                columnVisibilityModel={{
                    isAvailable: false,
                    isUsable: false
                }}
                getCellClassName={(params) => {
                    const rest = (params.row.rest !== 0)?params.row.rest.split(':'):0;
                    const driving = (params.row.driving !== 0)?params.row.driving.split(':'):0;
                    const workday = (params.row.workday !== 0)?params.row.workday.split(':'):0;
                    const cycle = (params.row.cycle !== 0)?params.row.cycle.split(':'):0;
                    
                    if(rest !== 0 && Number(rest[0]) < 1 && params.field === 'rest'){
                        return 'highLightWithRed';
                    }
                    if(driving !== 0 && Number(driving[0]) < 1  && params.field === 'driving'){
                        return 'highLightWithRed';
                    }
                    if(workday !== 0 && Number(workday[0]) < 1  && params.field === 'workday'){
                        return 'highLightWithRed';
                    }
                    if(cycle !== 0 && Number(cycle[0]) < 1 && params.field === 'cycle'){
                        return 'highLightWithRed';
                    }
                }}
            />
            <Dialog
        open={openDetailEditPanel}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            minHeight: "400px",
          },
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <TruckDriverDetailEditPanel
            onClose={handleCloseModal}
            truckDriverNo={truckDriverNo}

            // truckDriverData={detailEditPanelData}
            // handleSaveForm={handleSaveForm}
           
          />
        </DialogContent>
      </Dialog>
        </Box>
        //     </Box>
        // </TableContainer>
    )

}