import { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import { Typography } from "@mui/material";

import cancelicon from '../../assets/icons/cancel_icon.png'
import addicon from '../../assets/icons/add_icon.png'
import { Button } from "../styledComponents";


function Filter(props) {

    const { showFilter, setShowFilter, handleFilter } = props;
    // const [inputFields, setInputFields] = useState([
    //     { clause: '', field: '', operand: '',value:'' }   
    // ])

    const { searchClause, setSearchClause } = props

    const viewname = [
        { label: 'OR' },
        { label: 'AND' },
    ]
    console.log(props)
    const viewfield = props.searchFields
    // const viewfield = [
    //     { label: 'Control #', fieldName:"controlNumber" },
    //     { label: 'Order By', fieldName:"shipperAgentCode" },
    //     { label: 'Shipper' },
    // ]

    const viewnumber = [
        { label: 'IS' },
        { label: 'IS NOT' },
    ]

    const handleFilterClose = () => {
        setShowFilter(false);
    }

    const handleApplyFilter = () => {
        console.log('filter Object', searchClause)
        // const filter = searchClause.map(item => ({
        //     clause: item.clause.label,
        //     field: item.field.fieldName, 
        //     operand:item.operand.label,
        //     value: item.value,
        // }))
        handleFilter(searchClause);
        setShowFilter(false);
    }

    const handleAddnew = () => {
        let newfield = { clause: '', field: '', operand: '', value: '' }
        setSearchClause([...searchClause, newfield])
    }

    const handleRemoveField = (index) => {
        let data = [...searchClause];
        data.splice(index, 1)
        setSearchClause(data)
    }

    const onFieldChange = (index, value) => {
        let values = searchClause
        if (index === 0) {
            values[0].clause = { label: 'AND' }
        }
        values[index].field = value
        setSearchClause([...values])
    }

    const onClauseChange = (index, value) => {
        let values = searchClause
        if (index === 0) {
            values[0].clause = { label: 'AND' }
        }
        values[index].clause = value
        setSearchClause([...values])
    }

    const onOperandChange = (index, value) => {
        let values = searchClause
        if (index === 0) {
            values[0].clause = { label: 'AND' }
        }
        values[index].operand = value
        setSearchClause([...values])
    }

    const onValueChange = (index, value) => {
        console.log('Changing Value', value)
        let values = searchClause
        if (index === 0) {
            values[0].clause = { label: 'AND' }
        }
        values[index].value = value
        setSearchClause([...values])
    }

    return (
        <div style={{ width: '30%', position: 'absolute', zIndex: '10', backgroundColor: '#FFFFFF', marginLeft: '250px', marginTop: '265px', borderRadius: '5px', borderColor: '#DCDCDC', borderStyle: 'solid', borderWidth: '2px', visibility: (showFilter == true ? 'visible' : 'hidden') }} >

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '1rem', justifyContent: 'space-between' }}>
                <div style={{ width: '55%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button onClick={handleApplyFilter}>Apply</Button>
                    <Button onClick={() => props.loadDefaultView(null, null)}>Clear</Button>
                </div>
                <img src={cancelicon} style={{ width: '12px', height: '12px' }} onClick={handleFilterClose} />
                {/* <div style={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="h6" style={{ color: '#102254', fontWeight: 'bold', fontSize: '16px', paddingRight: '1rem' }} onClick={() => props.loadDefaultView(null, null)}> {`Clear Filter`} </Typography>
                    
                </div>

                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button onClick={handleApplyFilter}>Apply</Button>
                    <div style={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={cancelicon} style={{ width: '12px', height: '12px' }} onClick={handleFilterClose} />
                    </div>
                </div> */}
            </div>
            <div style={{ width: '94%', backgroundColor: '#707070', height: '0.5px', opacity: '40%', marginLeft: '1rem' }}></div>

            <div style={{ flexDirection: 'row', padding: '1rem', alignItems: 'center', display: 'flex', width: '100%', }}>
                <div style={{ width: "20%" }}>
                    <label>Where :</label>
                </div>
                <div style={{ width: "80%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        className="autocomplestyl"
                        id="combo-box-demo"
                        options={viewfield}
                        value={searchClause[0].field}
                        onChange={(event, value) => onFieldChange(0, value)}
                        style={{ width: '30%' }}
                        renderInput={(params) => <TextField {...params} />}
                        disabled
                    />

                    <Autocomplete
                        disablePortal
                        disableClearable
                        className="autocomplestyl"
                        id="combo-box-demo"
                        options={viewnumber}
                        value={searchClause[0].operand}
                        onChange={(event, value) => onOperandChange(0, value)}
                        // defaultValue={'is'}
                        style={{ width: '24%' }}
                        renderInput={(params) => <TextField {...params} />}
                        disabled
                    />

                    <TextField
                        disablePortal
                        size="small"
                        className="autocomplestyl"
                        id="combo-box-demo"
                        defaultValue={'value'}
                        value={searchClause[0].value}
                        style={{ width: '30%' }}
                        onChange={(event) => onValueChange(0, event.target.value)}
                        disabled
                    />
                    <div style={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={cancelicon} style={{ width: '12px', height: '12px' }} />
                    </div>
                </div>
            </div>

            {searchClause.map((input, index) => {
                if (index === 0)
                    return (<></>)
                return (
                    <div key={index} style={{ flexDirection: 'row', paddingLeft: '1rem', paddingRight: '1rem', alignItems: 'center', display: 'flex', marginBottom: '5px' }}>
                        <div style={{ width: "20%", marginright: '2px' }}>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                className="autocomplestyl"
                                id="combo-box-demo"
                                options={viewname}
                                // defaultValue={'OR'}
                                value={searchClause[index].clause}
                                onChange={(event, value) => onClauseChange(index, value)}
                                sx={{ width: '90%', paddingBottom: '2px' }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </div>
                        <div style={{ width: "80%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                className="autocomplestyl"
                                id="combo-box-demo"
                                options={viewfield}
                                // defaultValue={'Name'}
                                style={{ width: '30%' }}
                                renderInput={(params) => <TextField {...params} />}
                                value={searchClause[index].field}
                                onChange={(event, value) => onFieldChange(index, value)}
                            />

                            <Autocomplete
                                disablePortal
                                disableClearable
                                className="autocomplestyl"
                                id="combo-box-demo"
                                options={viewnumber}
                                // defaultValue={'is'}
                                sx={{ width: 75 }}
                                renderInput={(params) => <TextField {...params} />}
                                value={searchClause[index].operand}
                                onChange={(event, value) => onOperandChange(index, value)}
                            />

                            <TextField
                                className="autocomplestyl"
                                size="small"
                                id="combo-box-demo"
                                defaultValue={'value'}
                                style={{ width: '30%' }}
                                value={searchClause[index].value}
                                onChange={(event) => onValueChange(index, event.target.value)}
                            />
                            <div style={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleRemoveField(index)}>
                                <img src={cancelicon} style={{ width: '12px', height: '12px' }} />
                            </div>
                        </div>
                    </div>
                )
            })}

            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '1rem', paddingTop: '1rem', paddingBottom: '1rem' }} onClick={handleAddnew}>
                <img src={addicon} style={{ width: '15px', height: '15px' }} />
                <Typography variant="h6" style={{ color: '#102254', fontWeight: 'bold', fontSize: '16px', marginLeft: '0.5rem' }} > {`Add Filter`} </Typography>
            </div>

        </div>
    )

}
export default Filter;