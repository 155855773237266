import { Paper, Box, TableContainer } from "@mui/material";
import '../DispatchStyle.css'

import { useState, useEffect, useCallback } from 'react';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import CustomColumnMenu from "./CustomColumnMenu";
import { useSelector, useDispatch } from 'react-redux';
import _ from 'loadsh';
import { subItemTableColumns, setRowOrderByCookie, getRowOrderFromCookie, updateRowOrderByCookie } from '../commonFn';
import { reorderLoadsReq } from "../../../store/actions/dispatchActions";


const tableColumns = subItemTableColumns;

export default function SubItemTable(props) {
    const { row, getRowCount, stopsData, isLoading, isRowOrderEnabled } = props;
    const [isLoadingSubItem, setIsLoadingSubItem] = useState(false);
    const [rows, setRows] = useState([]);
    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );
    /**
     * updated on 06-oct-2023
     * handle column resizing
     */
    const apiRef = useGridApiRef();
    /**
     * ENd
     */

    useEffect(async () => {
        filterStopsData(stopsData).then((res) => {
            setRows(res);
            if (apiRef.current !== null) {
                apiRef.current.autosizeColumns({
                    includeHeaders: false,
                    includeOutliers: false,
                })
            }

        })
    }, [stopsData])

    const filterStopsData = useCallback(async (stops) => {
        if (stops.length > 0) {
            let objArr = [];
            stops.map((val) => {
                // if(Number(val.stopsCount) !== Number(val.lineNumber)){
                //     return false; 
                // }
                // if(val.stopsCount > 1){
                //     val.stop = `1-${val.stop}`
                // }
                if ( // drop loads condition
                    val.spotDate === 0 &&
                    val.arriveTruck !== 0 &&
                    val.arriveDriver !== 0 &&
                    val.departTruck === 0 &&
                    val.departDriver === 0 &&
                    val.arriveDriver === row.driverId && val.arriveTruck === row.truck
                ) {
                    objArr.push(val);
                } else if ( // pull loads condition
                    val.spotDate !== 0 &&
                    val.arriveTruck !== 0 &&
                    val.arriveDriver !== 0 &&
                    val.departTruck !== 0 &&
                    val.departDriver !== 0 &&
                    val.arriveTruckStatus === 'C' &&
                    (val.departDriver === row.driverId && val.departTruck === row.truck)
                ) {
                    objArr.push(val);
                } else if ( // pull loads condition - 2
                    val.spotDate !== 0 &&
                    val.arriveTruck !== 0 &&
                    val.arriveDriver !== 0 &&
                    val.departTruck !== 0 &&
                    val.departDriver !== 0 &&
                    (val.departDriver === row.driverId && val.departTruck === row.truck)
                ) {
                    objArr.push(val);
                } else if ( // live unloads condition
                    val.arriveTruck !== 0 &&
                    val.arriveDriver !== 0 &&
                    val.departTruck !== 0 &&
                    val.departDriver !== 0 &&
                    (val.departDriver === row.driverId && val.departTruck === row.truck)
                ) {
                    objArr.push(val);
                }
            })
            // Sort data by changedDate
            let sortedData = _.sortBy(objArr, function (o) {
                let sortByLastEvent = (o.changedDate) ? new Date(o.changedDate).getTime() * -1 : 1;
                let sortByDisplayPosition = (o.displayPosition !== null) ? o.displayPosition : '';
                return [sortByDisplayPosition, sortByLastEvent];
            });

            return sortedData;
        }
        return [];
    })

    /**
     * Commented on 28-02-2024
     * It requires more research to update the content on driver board
     */
    // const handleRowOrderChange = async (params) => {
    //     let cookieRes = getRowOrderFromCookie(row.truck);
    //     // localStorage.removeItem(row.truck); return;

    //     let dataObj = {
    //         controlNumber: params.row.control,
    //         displayPosition: params.targetIndex,
    //         truck: row.truck,
    //         lineNumber: params.row.lineNumber,
    //         unit: params.row.unit,
    //         apptDate: params.row.apptDate,
    //         apptTime: params.row.apptTime,
    //         stopName: params.row.stopName,
    //         shipperAgentCode: params.row.shipperAgentCode,
    //         shipperConsCode: params.row.shipperConsCode,
    //         customer: params.row.customer
    //     }
    //     if (cookieRes?.length > 0) {
    //         // Find item index using _.findIndex
    //         var index = _.findIndex(cookieRes, { controlNumber: params.row.control });

    //         // Replace item at index using native splice
    //         if (index !== -1) {
    //             cookieRes.splice(index, 1, dataObj);
    //         } else {
    //             cookieRes.push(dataObj);
    //         }

    //         let newArr = [];
    //         for (let i = 0; i < cookieRes.length; i++) {
    //             //console.log('before map-', cookieRes[i])
    //             let reorderObj = cookieRes[i];
    //             if (cookieRes[i]['displayPosition'] >= params.targetIndex && cookieRes[i]['controlNumber'] !== params.row.control) {
    //                 reorderObj = { ...cookieRes[i], displayPosition: Number(cookieRes[i]['displayPosition']) + Number(1) }
    //             }
    //             newArr.push(reorderObj);
    //         }

    //         localStorage.removeItem(row.truck);
    //         updateRowOrderByCookie(row.truck, newArr);
    //         updateDisplayPosition(newArr);
    //     } else {
    //         cookieRes.push(dataObj);
    //         updateRowOrderByCookie(row.truck, cookieRes);
    //         updateDisplayPosition(dataObj);
    //     }
    // }

    // const updateDisplayPosition = async (data) => {
    //     let postData = { loads: data }
    //     if (typeof ordersLocation?.locationNumber !== 'undefined') {
    //         setIsLoadingSubItem(true)
    //         // await reorderLoadsReq(postData, ordersLocation.locationNumber);
    //         setIsLoadingSubItem(false);
    //     }
    // }

    return (
        <TableContainer component={Paper} style={{ width: '100%' }} >

            <Box sx={{
                //boxShadow: 2,
                height: 'auto',
                width: '100%',
                //padding: '10px',
                backgroundColor: 'rgba(232, 228, 228, 1)',

                '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: "rgba(241, 246, 252, 1)",
                    color: "rgba(0, 0, 0, 1)",
                    fontSize: '11px'
                },
                '& .super-app-theme--cell': {
                    backgroundColor: 'white',
                    fontSize: '10px'

                },
            }}>
                <DataGridPro
                    rows={rows}
                    columns={tableColumns}
                    editMode="row"
                    columnHeaderHeight={30}
                    rowHeight={30}
                    checkboxSelection={false}
                    disableRowSelectionOnClick {...row}
                    hideFooter={true}
                    hideFooterRowCount={true}
                    slots={{
                        columnMenu: CustomColumnMenu
                    }}
                    loading={isLoading}
                    getCellClassName={(params) => {
                        if (params.row?.arriveTruckStatus === 'A' || params.row?.departTruckStatus === 'A') {
                            return 'assignedTenderClass';
                        } else {
                            return 'tenderedClass';
                        }
                    }}
                    getRowClassName={(params) => {
                        if (params.row?.arriveTruckStatus === 'A' || params.row?.departTruckStatus === 'A') {
                            return 'assignedTenderClass';
                        } else {
                            return 'tenderedClass';
                        }
                    }}
                    // autosizeOptions={{
                    //     columns: ['io'],
                    //     includeHeaders: true,
                    //     includeOutliers: true,
                    // }}
                    apiRef={apiRef}
                    rowReordering={isRowOrderEnabled}
                // onRowOrderChange={handleRowOrderChange}
                />
            </Box>
        </TableContainer>
    )



}